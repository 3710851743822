// utils/toggleLike.js
import { auth, db } from '../Firebase'; // Ensure the correct path
import {
  collection,
  doc,
  getDoc,
  deleteDoc,
  setDoc,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';

/**
 * Toggles the like status for a post and sends a notification if necessary.
 * @param {string} postId - The ID of the post to like/unlike.
 * @param {function} fetchLikes - Callback to refresh the like count.
 */
export const toggleLike = async (postId, fetchLikes) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      console.error("No authenticated user found.");
      return;
    }

    const likesRef = collection(db, 'posts', postId, 'likes');
    const likeDocRef = doc(likesRef, user.uid);
    const likeDoc = await getDoc(likeDocRef);

    if (likeDoc.exists()) {
      // If the user has already liked the post, remove their like
      await deleteDoc(likeDocRef);
      console.log("Like removed.");
    } else {
      // If the user hasn't liked the post, add their like
      await setDoc(likeDocRef, {
        userID: user.uid,
        postID: postId,
        timestamp: serverTimestamp(),
      });
      console.log("Like added.");

      // Fetch the post owner for notification
      const postDocRef = doc(db, 'posts', postId);
      const postDoc = await getDoc(postDocRef);

      if (postDoc.exists()) {
        const postOwnerId = postDoc.data().userID;

        // Avoid notifying the post owner if they liked their own post
        if (postOwnerId !== user.uid) {
          const notificationsCollection = collection(db, 'notifications');
          await addDoc(notificationsCollection, {
            read: false,
            recipientId: postOwnerId, // The owner of the post
            sourceId: postId, // The post ID
            type: 'liked', // Define the type of notification
            senderId: user.uid, // The user who liked the post
            timestamp: serverTimestamp(),
          });

          console.log(`Notification created for post owner (ID: ${postOwnerId}) about the like.`);
        }
      } else {
        console.warn(`Post with ID ${postId} not found. Skipping owner notification.`);
      }
    }

    // Fetch the updated like count
    if (fetchLikes) {
      fetchLikes(postId);
    }
  } catch (error) {
    console.error("Error toggling like:", error);
  }
};