import styles from '../styles/TopBubbles.css';
import BubblePill from './BubblePill';

const TopBubbles = ({ setTrendingTopic }) => {
  return (
    <div className="top-bubbles component">
      <div className="top-bubbles-container">
        <h5>Trending Topic Bubbles</h5>

        <div className="top-bubbles-list">
          {["music festival", "Minnesota", "events", "discuss", "artist"].map((topic) => (
            <button 
              key={topic} 
              onClick={() => {
                console.log(`Bubble clicked: ${topic}`);
                setTrendingTopic(topic);
              }} 
              className="bubble-button"
            >
              <BubblePill bubbleText={topic} />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopBubbles;