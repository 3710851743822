import { doc, getDoc, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../Firebase';

/**
 * Deletes a post with user confirmation and archives it to `deletedPosts`.
 * @param {string} postId - The ID of the post to delete.
 * @param {function} setPosts - A state updater function to update the posts in the UI.
 */

export const deletePostWithConfirmation = async (postId, setPosts) => {
  const userConfirmed = window.confirm("Are you sure you want to delete this post? This action cannot be undone.");
  if (!userConfirmed) return;

  try {
    const postDocRef = doc(db, 'posts', postId);
    const postSnapshot = await getDoc(postDocRef);

    if (!postSnapshot.exists()) {
      console.error("Post not found in Firestore.");
      return;
    }

    const postData = postSnapshot.data();

    // Copy the document to `deletedPosts` with the same document ID
    const deletedDocRef = doc(db, 'deletedPosts', postId);
    await setDoc(deletedDocRef, { ...postData, deletedTimestamp: serverTimestamp() });

    console.log("Post copied to deletedMessages successfully!");

    // Delete the document from `posts`
    await deleteDoc(postDocRef);

    console.log("Post deleted successfully from posts collection!");

    // Update local state to remove the post from the UI
    if (setPosts) {
      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
    }
  } catch (error) {
    console.error("Error deleting post:", error);
  }
};