import { collection, doc, getDocs, getDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import profilePlaceholder from '../assets/profile-placeholder.svg';

/**
 * Fetches comments for a post along with user data for each comment.
 * @param {string} postId - The ID of the post.
 * @param {object} db - Firestore database instance.
 * @param {object} storage - Firebase storage instance.
 * @returns {Promise<Array>} - List of comments with user data.
 */
export const fetchComments = async (postId, db, storage) => {
  try {
    const commentsRef = collection(db, 'posts', postId, 'comments');
    const commentsSnapshot = await getDocs(commentsRef);

    const comments = await Promise.all(
      commentsSnapshot.docs.map(async (commentDoc) => {
        const commentData = commentDoc.data();

        if (commentData.userID) {
          const commentUserDocRef = doc(db, 'users', commentData.userID);
          const commentUserDoc = await getDoc(commentUserDocRef);

          if (commentUserDoc.exists()) {
            const commentUserData = commentUserDoc.data();
            let profilePicUrl = commentUserData.profilePic || profilePlaceholder;

            if (profilePicUrl.startsWith('gs://')) {
              const profilePicRef = ref(storage, profilePicUrl);
              profilePicUrl = await getDownloadURL(profilePicRef);
            }

            return {
              id: commentDoc.id,
              ...commentData,
              commentUserHandle: commentUserData.handle,
              commentUserProfilePic: profilePicUrl,
            };
          }
        }

        // Fallback for missing or unknown user data
        return {
          id: commentDoc.id,
          ...commentData,
          commentUserHandle: 'Unknown User',
          commentUserProfilePic: profilePlaceholder,
        };
      })
    );

    return comments;
  } catch (error) {
    console.error('Error fetching comments:', error);
    return [];
  }
};