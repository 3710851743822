import styles from '../styles/ShimmerProfile.css'

const ShimmerProfile = () => {
  return (
    <div className="shimmerprofile">
      <div className="shimmer-circle"></div>
      <div className="shimmer-div"></div>
      <div className="shimmer-div-2"></div>
    </div>
  );
}

export default ShimmerProfile;
