import { auth, db } from '../Firebase'; // Adjust path as needed for your project
import {
  doc,
  updateDoc,
  collection,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';

/**
 * Toggles the follow status for a user and sends a notification if followed.
 * @param {string} userID - The ID of the user to follow or unfollow.
 * @param {function} setFollowingList - Function to update the local following list.
 * @param {function} setIsFollowing - Function to update the local following status.
 * @param {Array} followingList - Current list of users being followed.
 */
export const toggleFollow = async (userID, setFollowingList, setIsFollowing, followingList) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      console.error("No authenticated user found.");
      return;
    }

    const userDocRef = doc(db, 'users', user.uid);
    const isCurrentlyFollowing = followingList.includes(userID);

    if (isCurrentlyFollowing) {
      // Unfollow
      await updateDoc(userDocRef, {
        following: followingList.filter((id) => id !== userID),
      });
      setFollowingList((prev) => prev.filter((id) => id !== userID));
      console.log(`Unfollowed user with ID: ${userID}`);
    } else {
      // Follow
      await updateDoc(userDocRef, {
        following: [...followingList, userID],
      });
      setFollowingList((prev) => [...prev, userID]);
      console.log(`Followed user with ID: ${userID}`);

      // Create a notification for the followed user
      const notificationsCollection = collection(db, 'notifications');
      await addDoc(notificationsCollection, {
        read: false,
        recipientId: userID, // The user being followed
        senderId: user.uid, // The user who initiated the follow
        type: 'followed', // Define the type of notification
        timestamp: serverTimestamp(),
      });

      console.log(`Notification created for followed user (ID: ${userID}).`);
    }

    setIsFollowing((prev) => ({
      ...prev,
      [userID]: !isCurrentlyFollowing,
    }));
  } catch (error) {
    console.error("Error toggling follow status:", error);
  }
};