import { createContext, useState } from "react";

export const RadioContext = createContext();

export const RadioProvider = ({ children }) => {
    const [isRadioPlaying, setIsRadioPlaying] = useState(false);
    const [currentStationIndex, setCurrentStationIndex] = useState(0);
    const [isRadioVisible, setIsRadioVisible] = useState(false);

    const toggleRadio = () => {
        setIsRadioVisible((prev) => {
            console.log("Toggling Radio Visibility:", !prev); // Debugging visibility
            return !prev;
        });
        setIsRadioPlaying((prev) => !prev);
    };

    return (
        <RadioContext.Provider
            value={{
                isRadioPlaying,
                setIsRadioPlaying,
                currentStationIndex,
                setCurrentStationIndex,
                isRadioVisible,
                toggleRadio,
            }}
        >
            {children}
        </RadioContext.Provider>
    );
};