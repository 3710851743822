import { Outlet } from "react-router-dom";
import { useContext } from "react";
import { RadioContext } from "../context/RadioContext";
import RadioPlayer from "./RadioPlayer";

const Layout = () => {
    const { isRadioVisible } = useContext(RadioContext);

    return (
        <div className="layout">
            {/* Persistent RadioPlayer */}
            {isRadioVisible && <RadioPlayer />}
            {/* Render child routes */}
            <main>
                <Outlet />
            </main>
        </div>
    );
};

export default Layout;