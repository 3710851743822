// Import necessary Firebase modules
import { db } from '../Firebase'; // Ensure the path matches your project structure
import { collection, addDoc, query, where, getDocs, serverTimestamp } from 'firebase/firestore';

/**
 * Extracts mentions from the content and saves them in the Firestore 'mentions' and 'notifications' collections.
 * @param {string} content - The content of the post containing mentions.
 * @param {string} userId - The ID of the user creating the post.
 * @param {string} postId - The ID of the post where the mentions are found.
 */
export const saveMentions = async (content, userId, postId) => {
  try {
    // Extract mentions from the content (e.g., "@username")
    const mentionRegex = /@([a-zA-Z0-9_-]+)/g;
    const mentions = [];
    let match;

    while ((match = mentionRegex.exec(content)) !== null) {
      mentions.push(match[1]); // Capture the username part (without '@')
    }

    if (mentions.length === 0) {
      console.log('No mentions found in the content.');
      return; // No mentions to save
    }

    console.log('Mentions extracted:', mentions);

    // Check Firestore for user handles and save only valid ones
    const usersCollection = collection(db, 'users');
    const validMentions = [];

    for (const mention of mentions) {
      const q = query(usersCollection, where('handle', '==', mention));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        validMentions.push({
          handle: mention,
          userId: userDoc.id, // Add the user's Firestore document ID
        });
      }
    }

    if (validMentions.length === 0) {
      console.log('No valid mentions found in Firestore.');
      return; // No valid mentions to save
    }

    console.log('Valid mentions:', validMentions);

    // Save mentions and notifications
    const mentionsCollection = collection(db, 'mentions');
    const notificationsCollection = collection(db, 'notifications');

    for (const mention of validMentions) {
      // Save the mention first
      const mentionDocRef = await addDoc(mentionsCollection, {
        mentionedUserHandle: mention.handle,
        postId: postId,
        mentionedBy: userId,
        timestamp: serverTimestamp(),
      });

      console.log(`Mention saved with ID: ${mentionDocRef.id}`);

      // Create a notification using the mention document ID as sourceId
      await addDoc(notificationsCollection, {
        read: false,
        recipientId: mention.userId, // ID of the mentioned user
        senderId: userId,
        sourceId: mentionDocRef.id, // ID of the mention document
        timestamp: serverTimestamp(),
        type: "mentioned"
      });

      console.log(`Notification created for mention ID: ${mentionDocRef.id}`);
    }

    console.log('Mentions and notifications saved successfully.');
  } catch (error) {
    console.error('Error saving mentions:', error);
  }
};