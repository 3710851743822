import { useEffect, useState } from "react";
import * as Icon from 'react-feather';
import FeaturedEvents from '../components/FeaturedEvents';
import Header from '../components/Header';
import EventCard from '../components/EventCard';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from '../styles/EventsPage.css'
import PrimaryButton from "../components/PrimaryButton";
import SecondaryButton from "../components/SecondaryButton";

const EventsPage = () => {

    const [startDate, setStartDate] = useState(null);

    const [isAllActive, setIsAllActive] = useState(true);
    const [isUpcomingActive, setIsUpcomingActive] = useState(false);
    const [isNewActive, setIsNewActive] = useState(false);

    const toggleAllTab = () => {
        setIsAllActive(true)
        setIsUpcomingActive(false)
        setIsNewActive(false)
    }

    const toggleUpcomingTab = () => {
        setIsAllActive(false)
        setIsUpcomingActive(true)
        setIsNewActive(false)
    }

    const toggleNewTab = () => {
        setIsAllActive(false)
        setIsUpcomingActive(false)
        setIsNewActive(true)
    }

    return (
        <div className="events-page">
            <Header showLogo goBack />
            <div className='events-page-hero'>
                
                <div className="events-page-hero-main">
                    <div className='hero-texts'>
                        <p className='events-header-text'>Where the underground comes alive.</p>
                        <h2 className='events-subheader-text'>Hear them now, before the world catches on.</h2>
                    </div>
                </div>
                <FeaturedEvents />
            </div>

            {/* ALL EVENTS */}
            <div className='all-events-list'>
                <div className='all-events-header'>
                    <div className='section-title-and-tabs'>
                        <h3 style={{fontSize: 'var(--text-xl)'}}>All Events</h3>
                            <div className='eventspage-tabs'>
                            <div className='tab' onClick={toggleAllTab}>
                                <p style={{ color: isAllActive ? 'var(--cyan)' : 'var(--text-grey)' }} className='tabname'>All</p>
                                <div className="tab-underline" style={{ backgroundColor: isAllActive ? 'var(--cyan)' : 'transparent' }}></div>
                            </div>
                            <div className='tab' onClick={toggleUpcomingTab}>
                                <p style={{ color: isUpcomingActive ? 'var(--cyan)' : 'var(--text-grey)' }} className='tabname'>Upcoming</p>
                                <div className="tab-underline" style={{ backgroundColor: isUpcomingActive ? 'var(--cyan)' : 'transparent' }}></div>
                            </div>
                            <div className='tab' onClick={toggleNewTab}>
                                <p style={{ color: isNewActive ? 'var(--cyan)' : 'var(--text-grey)' }} className='tabname'>New</p>
                                <div className="tab-underline" style={{ backgroundColor: isNewActive ? 'var(--cyan)' : 'transparent' }}></div>
                                </div>
                        </div>
                    </div>
                    
                    <div className='eventpage-searchbar'>
                        {/* <Icon.Search size={20} /> */}
                        <input type='text' placeholder='Search' />
                    </div>
                </div>

                <div className="all-events-content">
                    <div className="events-results">
                        <div className="all-events">
                            <EventCard />
                            <EventCard />
                            <EventCard />
                            <EventCard />
                        </div>

                        <div className="load-more-events">
                            <SecondaryButton buttonLabel="Load More Events" />
                        </div>
                    </div>
                    

                    <div className="events-side-panel">
                        <p className="panel-title">Advanced Search</p>

                        <div className="events-filter-location form-field">
                            <label className="label">Location</label>
                            <input className="input-event-filter-loc" />
                        </div>

                        <div className="events-filter-genre form-field">
                            <label className="label">Genre</label>
                            <select className="input-dropdown" >
                                <option>--Select a genre--</option>
                                <option>House</option>
                                <option>Funk</option>
                                <option>Boogie</option>
                            </select>
                        </div>

                        <div className="events-filter-type form-field">
                            <label className="label">Event Type</label>
                            <select className="input-dropdown" >
                                <option>--Select an event type--</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                            </select>
                        </div>

                        <div className="filter-by-date">
                            <label className="label">Event Date</label>
                            <div className="event-page-datepicker">
                                <Icon.Calendar size={14} color="var(--acc-blue)" className="calendar-icon" />
                                <DatePicker
                                    className="datepicker"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    dateFormat="dd MMM yyyy"
                                />
                            </div>
                        </div>

                        <div className="filter-button">
                            <PrimaryButton buttonLabel="Go" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventsPage;
