import styles from '../styles/SubmitEventBanner.css';
import bannerbg from "../assets/bg-dark.png"
import submergeLogo from "../assets/submerge-logo.png"
import SecondaryButton from './SecondaryButton';

const SubmitEventBanner = () => {
    return (
        <div className="submit-event-banner component">
            <div className="img-banner">
                <div className='submerge-logo'>
                    <img src={submergeLogo} />
                </div>
                <div className='texts'>
                <p className='banner-text'>Have an event you want to promote?</p>
                    <div className='banner-cta'>
                        <SecondaryButton buttonLabel="Submit an Event" />
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default SubmitEventBanner;