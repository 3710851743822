import FeaturedEvents from '../components/FeaturedEvents';
import Header from '../components/Header';
import styles from '../styles/EventCard.css'
import PrimaryButton from './PrimaryButton';

const EventCard = () => {
    return (
        <div className="event-card-container">
            <div className='event-card-content'>
                <div className='event-card-image'>
                    <img src="https://i.pinimg.com/736x/62/cf/f6/62cff64dd4ad3de7a66acb60f4ec27b3.jpg" />
                </div>

                <div className='event-card-details'>
                    <p className='eventcard-eventdate'>12 January 2024</p>
                    <p className='eventcard-eventname'>Event name</p>
                    <p className='eventcard-genre'>House, Boogie</p>
                </div>

                <div className='event-card-button'>
                    <PrimaryButton buttonLabel="Get Tickets" />
                </div>
                
            </div>
        </div>
    );
}

export default EventCard;
