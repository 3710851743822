import { useEffect, useState } from "react";
import NewsfeedCard from '../components/NewsfeedCard';
import CreatePost from '../components/CreatePost';
import ProfilePanel from '../components/ProfilePanel';
import TopBubbles from '../components/TopBubbles';
import BubblePill from '../components/BubblePill';
import TertiaryButton from '../components/TertiaryButton';
import DiscoverPeoplePanel from '../components/DiscoverPeoplePanel';
import DiscoverPeopleSlider from '../components/DiscoverPeopleSlider';
import Header from '../components/Header';
import { auth, db } from '../Firebase';
import { collection, doc, getDoc, getDocs, orderBy, query  } from 'firebase/firestore';
import styles from '../styles/MainScreen.css';
import RadioPlayer from "../components/RadioPlayer";

function MainScreen() {
  const [isTrendingActive, setIsTrendingActive] = useState(true);
  const [isFollowingActive, setIsFollowingActive] = useState(false);
  const [trendingTopic, setTrendingTopic] = useState('');
  const [userData, setUserData] = useState(null);
  const [posts, setPosts] = useState([]);

  const toggleView = () => {
    setIsTrendingActive((prev) => !prev);
    setIsFollowingActive((prev) => !prev);
  };

  const fetchUserData = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserData({ uid: user.uid, ...userDoc.data() });
        } else {
          console.log('User document does not exist');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const addPost = (newPost) => {
    setPosts((prevPosts) => [newPost, ...prevPosts]);
  }

  const handleClear = () => {
    try {
      console.log('Clear button clicked');
      setTrendingTopic('');
    } catch (error) {
      console.error('Error in handleClick:', error);
    }
  };

  return (
    <div className="mainscreen">
      <Header 
        avatar="true" 
        radioButton="true"
        showLogo="true"
      />

      <main className="mainscreen-content">
        <div className="profile-pane">
          <ProfilePanel userData={userData} />
        </div>

        <div className="feed">
          <div className="create-post-section">
            <CreatePost userData={userData} addPost={addPost} />
          </div>

          {trendingTopic !== '' && (
            <div className="active-bubble">
              <div className="left-texts">
                <p className="text">Showing posts of</p>
                <BubblePill bubbleText={trendingTopic} />
                <p className="text">bubble.</p>
              </div>
              <div className="right-texts">
                <TertiaryButton buttonLabel="Clear" onClick={() => setTrendingTopic('')} />
              </div>
            </div>
          )}

          <div className="newsfeed-tabs">
            <div className="tab-item" onClick={toggleView}>
              <div className="label">
                <button className="tab-button">
                  <span 
                    style={{ color: isTrendingActive ? 'var(--cyan)' : 'var(--text-grey)' }}>
                    Trending For You
                  </span>
                </button>
              </div>
              <div className="tab-underline" 
                   style={{ backgroundColor: isTrendingActive ? 'var(--cyan)' : 'transparent' }}>
              </div>
            </div>

            <div className="tab-item" onClick={toggleView}>
              <div className="label">
                <button className="tab-button">
                  <span 
                    style={{ color: isFollowingActive ? 'var(--cyan)' : 'var(--text-grey)' }}>
                    Following
                  </span>
                </button>
              </div>
              <div className="tab-underline" 
                   style={{ backgroundColor: isFollowingActive ? 'var(--cyan)' : 'transparent' }}>
              </div>
            </div>
          </div>          

          <div className="newsfeed-section">
            {userData && (
              <NewsfeedCard 
                userUID={userData.uid} // Pass the authenticated user's UID to NewsfeedCard
                trendingTopic={trendingTopic}
                {...(isTrendingActive ? { isTrendingActive } : {})}
                {...(isFollowingActive ? { isFollowingActive } : {})}
              />
            )}
          </div>
          
          
          <DiscoverPeopleSlider />

          <div>
            <p style={{padding: '8px 0', visibility: 'hidden', textAlign: 'center'}}>I'll be just here as spacer. Can't see me, but I'm here.</p>
          </div>
        </div>

        <div className="web-right-panel">
          <TopBubbles setTrendingTopic={setTrendingTopic} />
          {userData && <DiscoverPeoplePanel userData={userData} onFollow={fetchUserData} />}
        </div>

        
      </main>
    </div>
  );
}

export default MainScreen;