import styles from '../styles/TertiaryButton.css';

const TertiaryButton = ({ buttonLabel, type = 'button', onClick }) => {
  return (
    <div className="tertiary-button">
      <button
        className="button"
        type={type}
        onClick={(e) => {
          console.log('Button clicked:', e);
          if (onClick) {
            onClick(e); // Ensure the onClick handler is invoked properly
          }
        }}
      >
        {buttonLabel}
      </button>
    </div>
  );
};

export default TertiaryButton;
