import styles from '../styles/EventDetailsPage.css';
import BubblePill from '../components/BubblePill';
import * as Icon from 'react-feather';
import performerIcon from '../assets/performer-icon.svg'
import eventPlaceholder from '../assets/event-poster-ai.png'
import PrimaryButton from '../components/PrimaryButton'
import SecondaryButton from '../components/SecondaryButton'
import SubmitEventBanner from '../components/SubmitEventBanner';

const EventDetailsPage = () => {
    return (
        <div className="event-details-page">

            <div className="event-details-heading">
                <div className='grid-left'>
                    <div className='back-btn'>
                        <Icon.ChevronLeft size={32} />
                    </div>
                    <p className='event-details-title'>
                        Dive Into the Underground: A Night of Raw, Unfiltered Music
                    </p>
                </div>
                

                <div className='event-actions'>
                    <div className='save-event-btn'>
                        <Icon.Heart size={20} color='var(--cyan)'/>
                    </div>
                </div>
            </div>

            <div className='event-deets-main'>
                {/* LEFT SIDE CONTENT : ALL DETAILS */}
                <div className='event-deets-content'> 
                    <div className='event-deets-loc deets-row'>
                        <div className='deets-icon'>
                            <Icon.MapPin size={20} />
                        </div>
                        <div className='deets-texts'>
                            <p className='deets-label'>Location</p>
                            <p className='deets-value'>Echo Underground Club, 315 Vibe Street, Downtown City</p>
                        </div>
                        
                    </div>

                    <div className='event-deets-date deets-row'>
                        <div className='deets-icon'>
                            <Icon.Calendar size={20} />
                        </div>
                        <div className='deets-texts'>
                            <p className='deets-label'>Date & Time</p>
                            <p className='deets-value'>February 17, 2025 | 7:00 PM - 11:30 PM</p>
                        </div>
                    </div>

                    <div className='event-deets-ticket deets-row'>
                        <div className='deets-icon'>
                            <Icon.DollarSign size={20} />
                        </div>
                        <div className='deets-texts'>
                            <p className='deets-label'>Ticket Price</p>
                            <p className='deets-value'>Free Admission</p>
                        </div>
                    </div>
                    <div className='event-deets-performers deets-row'>
                        <div className='deets-image' style={{width: '22px'}}>
                            <img src={performerIcon} />
                        </div>
                        <div className='deets-texts'>
                            <p className='deets-label'>Performers</p>
                            <p className='deets-value performers-names'>DJ Shadowpulse, DJ Reverie, Neon Oracle, Bassline Maverick, Voltage Vixen, EchoFreq</p>
                        </div>
                    </div>
                    <div className='event-deets-genre deets-row'>
                        <div className='deets-icon'>
                            <Icon.Music size={20} />
                        </div>
                        <div className='deets-texts'>
                            <p className='deets-label'>Genre</p>
                            <div className='deets-value event-genres'>
                                <BubblePill bubbleText="Boogie" />
                                <BubblePill bubbleText="House" />
                                <BubblePill bubbleText="Techno" />
                            </div>
                        </div>
                    </div>

                    <div className='event-deets-description'>
                        <p>
                        What’s in Store for You? <br />
                        <br />
                        🎤 Unforgettable Live Performances<br />
                        From raw, soulful vocals to electrifying beats, we’re bringing together some of the most talented underground artists. Witness their passion as they take the stage and create an atmosphere unlike anything you’ve experienced before.<br />
                        <br />
                        🎧 A Multi-Genre Musical Journey<br />
                        Expect a diverse lineup of music that breaks all the rules. From underground electronic grooves and lo-fi hip-hop rhythms to high-energy punk rock and avant-garde experimental tracks, there’s something to satisfy every music enthusiast.<br />
                        <br />
                        🎨 Immersive Visual Art & Installations<br />
                        Step into a space transformed by cutting-edge art and interactive visuals. Stunning projections and installations will amplify the energy of the music and take you on a sensory journey.<br />
                        <br />
                        🍹 Crafted Drinks & Bites<br />
                        Our mixologists have designed a menu of drinks that match the event’s edgy and vibrant vibe. Pair them with tasty bites while mingling with fellow music lovers.<br />
                        </p>
                    </div>

                    <div className='event-deets-organizers deets-row-two'>
                        <p className='deets-row-two-label'>Event Organizer: </p>
                        <p className='deets-row-two-value'>
                            John Doe
                        </p>
                    </div>

                    <div className='event-deets-organizers deets-row-two'>
                        <p className='deets-row-two-label'>Contact Organizer: </p>
                        <a href='mailto:business@johndoe.com' className='deets-row-two-value'>
                            events@johndoe.com
                        </a>
                    </div>

                </div>

                {/* RIGHT SIDE CONTENT : POSTER AND CTA */}

                <div className='event-poster-and-cta'>
                    <div className='event-deets-poster'>
                        <img src={eventPlaceholder} />
                    </div>
                    <div className='get-tix-btn'>
                        <PrimaryButton buttonLabel="GET TICKETS" />
                    </div>
                </div>
                
                
            </div>

            <SubmitEventBanner />

        </div>
    );
};

export default EventDetailsPage;