import styles from '../styles/UploadTrack.css';
import * as Icon from 'react-feather';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage, auth } from '../Firebase'; 
import { addDoc, collection, setDoc } from 'firebase/firestore';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';

const UploadTrack = ( {isVisible, onClose, children}) => {
	/**** Genre ****/
	// Predefined list of options for artists
	const options = ['John Doe', 'Dylan Thomas', 'Patty Smith', 'Charlie Puth'];
	
	// Genre
	const genreOptions = ["House"];
	const [selectedGenreOptions, setSelectedGenreOptions] = useState("");
	const handleGenreChange = (event) => {
		setSelectedGenreOptions(event.target.value);
	};

	// Sub-genre
	const subgenreOptions = ["Deep", "Jackin", "Disco", "Afro"];
	const [selectedSubgenreOptions, setSelectedSubgenreOptions] = useState("");
	const handleSubgenreChange = (event) => {
		setSelectedSubgenreOptions(event.target.value);
	};

	/***** THUMBNAIL UPLOAD ****/
	const [preview, setPreview] = useState(null);

	const handleThumbnailChange = (event) => {
	const thumbnail = event.target.files[0];
	if (thumbnail) {
		const reader = new FileReader();
			reader.onloadend = () => {
			setPreview(reader.result); // Set the thumbnail preview
		};
		  reader.readAsDataURL(thumbnail); // Read the thumbnail as a Data URL
		} else {
		  	setPreview(null); // Reset preview if no thumbnail
		}
	};

	/***** AUDIO FILE UPLOAD ****/
	const [audio, setAudio] = useState(null);
	const [audioFile, setAudioFile] = useState(null);
	const [dragOver, setDragOver] = useState(false);
	const [uploading, setUploading] = useState(false);

	const handleAudioChange = (event) => {
		const audioFile = event.target.files[0];
	    if (audioFile) {
	      setAudio(audioFile.name); // Set the audio audioFile name
	    } else {
	      setAudio(null); // Reset the audioFile name if no audioFile is selected
	    }
	};

	// Validate and handle audio file uploads
	const handleFileSelection = (file) => {
		const allowedExtensions = ['mp3', 'wav', 'aac', 'flac', 'ogg'];
		const fileExtension = file.name.split('.').pop().toLowerCase();

		if (!allowedExtensions.includes(fileExtension)) {
			alert("Invalid file type. Please upload an audio file.");
			return;
		}

		setAudioFile(file);
	};

	// Handle file selection via input
	const handleFileInputChange = (event) => {
		const file = event.target.files[0];
		if (file) handleFileSelection(file);
	};

	// Handle drag-over events
	const handleDragOver = (event) => {
		event.preventDefault();
		setDragOver(true);
	};

	// Handle drag-leave events
	const handleDragLeave = () => setDragOver(false);

	// Handle file drop events
	const handleDrop = (event) => {
		event.preventDefault();
		setDragOver(false);
		const file = event.dataTransfer.files[0];
		if (file) handleFileSelection(file);
	};

	/****  ARTIST ****/
	const predefinedOptions = ['Dylan Thomas', 'Bruno Mars', 'Eminem', 'Astro'];
	// Artist values
	// Artist values
	const [inputArtistValue, setInputArtistValue] = useState('');
	const [artistValues, setArtistValues] = useState([]);
	const [showArtistDropdown, setShowArtistDropdown] = useState(false);

	const handleArtistInputChange = (e) => {
		setInputArtistValue(e.target.value);
		if (e.target.value.trim() !== '') {
			setShowArtistDropdown(true);
		} else {
			setShowArtistDropdown(false); // Hide dropdown when input is empty
		}
	};

	const handleArtistKeyDown = (e) => {
		if (e.key === 'Enter' || e.key === ',') {
			e.preventDefault();
			if (inputArtistValue.trim() !== '') {
				addArtistValue(inputArtistValue.trim());
			}
		}
	};

	const addArtistValue = (value) => {
		if (!artistValues.includes(value)) {
			setArtistValues((prevValues) => [...prevValues, value]);
		}
		setInputArtistValue(''); // Clear input
		setShowArtistDropdown(false);
	};

	const handleRemoveArtistValue = (valueToRemove) => {
		setArtistValues((prevValues) => prevValues.filter((value) => value !== valueToRemove));
	};

	const handleArtistOptionClick = (option) => {
		addArtistValue(option);
	};

	// Remixer Values
	const [inputRemixerValue, setInputRemixerValue] = useState('');
	const [remixerValues, setRemixerValues] = useState([]);
	const [showRemixerDropdown, setShowRemixerDropdown] = useState(false);
	const handleRemixerInputChange = (e) => {
		setInputRemixerValue(e.target.value);
		if (e.target.value.trim() !== '') {
			setShowRemixerDropdown(true);
		} else {
			setShowRemixerDropdown(false); // Hide dropdown when input is empty
		}
	};
	
	const handleRemixerKeyDown = (e) => {
		if (e.key === 'Enter' || e.key === ',') {
			e.preventDefault();
			if (inputRemixerValue.trim() !== '') {
				addRemixerValue(inputRemixerValue.trim());
			}
		}
	};
	
	const addRemixerValue = (value) => {
		if (!remixerValues.includes(value)) {
			setRemixerValues((prevValues) => [...prevValues, value]);
		}
		setInputRemixerValue(''); // Clear input
		setShowRemixerDropdown(false);
	};
	
	const handleRemoveRemixerValue = (valueToRemove) => {
		setRemixerValues((prevValues) => prevValues.filter((value) => value !== valueToRemove));
	};
	
	const handleRemixerOptionClick = (option) => {
		addRemixerValue(option);
	};

	/**** TRACK DETAILS *****/
	const [trackTitle, setTrackTitle] = useState("");
	const [releasedYear, setReleasedYear] = useState("");
	const [remixTitle, setRemixTitle] = useState("");
	const [remixer, setRemixer] = useState("");
	const [imprint, setImprint] = useState("");
	const [bpm, setBpm] = useState("");

	// THIS POPUP isVisible
	if (!isVisible) return null;

	/**** Upload the audio file to Firebase and create metadata in Firestore ****/
	const handleUpload = async () => {
		if (!audioFile) {
			alert("Please upload an audio file first.");
			return;
		}
	
		if (!trackTitle.trim()) {
			alert("Please enter a track title.");
			return;
		}
	
		if (!releasedYear.trim()) {
			alert("Please enter a release year.");
			return;
		}
	
		setUploading(true);
		const userId = auth.currentUser?.uid;
	
		if (!userId) {
			alert("User not authenticated.");
			setUploading(false);
			return;
		}
	
		try {
			// Upload the audio file to Firebase Storage
			const storageRef = ref(storage, `Tracks/${userId}/${audioFile.name}`);
			await uploadBytes(storageRef, audioFile);
	
			// Get the file's download URL
			const fileURL = await getDownloadURL(storageRef);
	
			// Add metadata to Firestore with a unique document ID
			const tracksCollectionRef = collection(db, "tracks");
			await addDoc(tracksCollectionRef, {
				artists: artistValues,
				remixers: remixerValues,
				title: trackTitle,
				remixTitle,
				releasedYear,
				imprint,
				url: fileURL,
				userId,
				uploadedAt: new Date(),
				genre: selectedGenreOptions,
				subgenre: selectedSubgenreOptions,
				bpm,
			});
	
			alert("Track uploaded successfully!");
		} catch (error) {
			console.error("Error uploading file:", error);
			alert("Failed to upload track. Please try again.");
		} finally {
			setUploading(false);
			setAudioFile(null);
			setTrackTitle(""); // Reset track title
			setReleasedYear(""); // Reset released year
		}
	};
	
	return (

		<div className="upload-track-overlay">
			<div className="upload-track-card" onClick={(e) => e.stopPropagation()}>
				{children}
				<div className="upload-track-header">
					<p className="overlay-title">Upload Track</p>
					<div className="close-overlay-button" onClick={onClose}>
						<Icon.X color='#fff' size={18} />
					</div>
				</div>

				<hr />

				<form className="upload-track-form">
					<div className="input-fields-section">

						<div className="media-uploader form-field">
							<label className="label"> Track Thumbnail </label>
							<div className="upload-thumbnail">
								{preview && (
								<div className="thumbnail-preview">
									<img className="image-preview" src={preview} alt="Image Preview" />
								</div>
								)}
								<div className="file-uploader">
									<div className="uploader-icon">
										<Icon.Image size={32} color="var(--acc-blue)"/>
									</div>
									<label for="track-thumbnail" className="clickable-label">Click here to upload the track's cover art</label>
									<input 
										id="track-thumbnail" 
										type="file" 
										onChange={handleThumbnailChange}
        								accept="image/*"
										style={{display: 'none'}}
										required
									/>
								</div>

							</div>
							

						</div>
						
						<div className="form-track-artist form-field">
							<label className="label">
								Artist(s)
								<span className="required-asterisk"> * </span>
							</label>
							<div className="selected-options">
								{artistValues.length > 0 && (
									<div className="artists-tags">
										<ul className="selected-artists-list">
											{artistValues.map((value, index) => (
												<li key={index} className="selected-tags">
													{value}
													<button onClick={() => handleRemoveArtistValue(value)}>
														<Icon.X size={16} style={{ margin: '2px 0 0 0' }} />
													</button>
												</li>
											))}
										</ul>
									</div>
								)}
							</div>
							<input
								type="text"
								value={inputArtistValue}
								onChange={handleArtistInputChange}
								onKeyDown={handleArtistKeyDown}
								onFocus={() => setShowArtistDropdown(true)}
								placeholder="Type to add artists"
								className="multi-input-artists input-field"
								required
							/>
							<p className="subtext">Separate artists with comma, or by pressing enter.</p>
							{showArtistDropdown && inputArtistValue.trim() !== '' && (
								<div className="artist-options">
									<ul className="artist-dropdown">
										{predefinedOptions
											.filter((option) => option.toLowerCase().includes(inputArtistValue.toLowerCase()) && !artistValues.includes(option))
											.map((option, index) => (
												<li key={index} onClick={() => handleArtistOptionClick(option)} className="dropdown-item">
													{option}
												</li>
											))}
									</ul>
								</div>
							)}
						</div>

						<div className="form-track-remixer form-field">
							<label className="label">
								Remixer(s)
							</label>
							<div className="selected-options">
								{remixerValues.length > 0 && (
									<div className="remixers-tags">
										<ul className="selected-remixers-list">
											{remixerValues.map((value, index) => (
												<li key={index} className="selected-tags">
													{value}
													<button onClick={() => handleRemoveRemixerValue(value)}>
														<Icon.X size={16} style={{ margin: '2px 0 0 0' }} />
													</button>
												</li>
											))}
										</ul>
									</div>
								)}
							</div>
							<input
								type="text"
								value={inputRemixerValue}
								onChange={handleRemixerInputChange}
								onKeyDown={handleRemixerKeyDown}
								onFocus={() => setShowRemixerDropdown(true)}
								placeholder="Type to add remixers"
								className="multi-input-remixers input-field"
							/>
							<p className="subtext">Separate remixers with comma, or by pressing enter.</p>
							{showRemixerDropdown && inputRemixerValue.trim() !== '' && (
								<div className="remixer-options">
									<ul className="remixer-dropdown">
										{predefinedOptions
											.filter((option) => option.toLowerCase().includes(inputRemixerValue.toLowerCase()) && !remixerValues.includes(option))
											.map((option, index) => (
												<li key={index} onClick={() => handleRemixerOptionClick(option)} className="dropdown-item">
													{option}
												</li>
											))}
									</ul>
								</div>
							)}
						</div>

						<div className="form-track-title form-field">
							<label htmlFor="track-title" className="label">
								Track Title
								<span className="required-asterisk"> * </span>
							</label>
							<input
								id="track-title"
								required
								className="input-field"
								value={trackTitle}
								onChange={(e) => setTrackTitle(e.target.value)}
							/>
						</div>

				        <div className="form-track-remix-title form-field">
							<label htmlFor="track-remix-title" className="label">
								Remix
							</label>
							<input
						        id="track-remix-title"
								placeholder="Remix Title"
								className="input-field"
								value={remixTitle}
								onChange={(e) => setRemixTitle(e.target.value)}
							/>
						</div>

				        <div className="form-track-release-year form-field">
							<label className="label">
								Released Year
								<span className="required-asterisk"> * </span>
							</label>
							<input
								type="text"
								placeholder="Enter released year"
								className="input-field"
								required
								value={releasedYear}
								onChange={(e) => setReleasedYear(e.target.value)}
							/>
						</div>

				       <div className="form-track-imprint form-field">
					       <label htmlFor="track-imprint" className="label">
								Label
						   </label>
						   <input
						   		id="track-imprint"
								type="text"
								className="input-field"
								value={imprint}
								onChange={(e) => setImprint(e.target.value)}

						   />
					   </div>
					   
					    <div className="form-track-uploader form-field">
							<label className="label">Upload a track file</label>
							<div
								className={`upload-audio ${dragOver ? 'drag-over' : ''}`}
								onDragOver={handleDragOver}
								onDragLeave={handleDragLeave}
								onDrop={handleDrop}
							>
								{audioFile ? (
									<div className="audio-file">
										<Icon.CheckCircle size={14} color="var(--acc-blue)" />
										<p className="audio-file-name">{audioFile.name}</p>
									</div>
								) : (
									<div className="file-uploader">
										<div className="uploader-icon">
											<Icon.Music size={32} color="var(--acc-blue)" />
										</div>
										<label htmlFor="audio-file" className="clickable-label">
											Click here or drag a track file to upload
										</label>
										<input
											id="audio-file"
											type="file"
											onChange={handleFileInputChange}
											accept="audio/*"
											style={{ display: 'none' }}
										/>
									</div>
								)}
							</div>
						</div>
						
						<div className="form-track-bpm form-field">
							<label htmlFor="track-bpm" className="label">
								BPM
							</label>
							<input
								id="track-bpm"
								type="text"
								className="input-field"
								value={bpm}
								onChange={(e) => setBpm(e.target.value)}
							/>
						</div>
				        <div className="form-genre form-field">
				            <label className="label">
				            	Genre
				            	<span className="required-asterisk"> * </span>
				            </label>
				            <select className="dropdown-genre">
				           		<option value="" hidden>Select a genre</option>
						        {/* Dynamically create option elements from array */}
						        {genreOptions.map((option, index) => (
						        	<option key={index} value={option} className="genre-options-list" required>
										{option}
									</option>
						        ))}
				            </select>
				        </div>

				        {/* Disable when no subgenre under the main genre */}
				        <div className="form-subgenre form-field">
				            <p className="label">Sub-genre</p>
				            <select className="dropdown-genre">
				           		<option value="" hidden>Select a sub-genre</option>
						        {/* Dynamically create option elements from array */}
						        {subgenreOptions.map((option, index) => (
						        	<option key={index} value={option} className="subgenre-options-list" required>
										{option}
									</option>
						        ))}
				            </select>
				        </div>

				        <div className="form-buttons">
							
							<PrimaryButton
								buttonLabel={uploading ? "Uploading..." : "Upload"}
								onClick={handleUpload}
								disabled={uploading}
							/>
						</div>

					</div>
				</form>

			</div>
		</div>

	)
}

export default UploadTrack;