import { doc, getDoc } from 'firebase/firestore';
import profilePlaceholder from '../assets/profile-placeholder.svg'; // Adjust the path as needed

/**
 * Fetch user data from Firestore.
 * @param {string} uid - The user ID whose data should be fetched.
 * @param {object} db - The Firestore database instance.
 * @returns {Promise<object>} - A promise that resolves to the user data including handle and profile picture.
 */

const fetchUserData = async (uid, db) => {
  try {
    const userDocRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return {
        handle: `@${userData.handle || 'username'}`,
        profilePic: userData.profilePic || profilePlaceholder,
        market: userData.market || 'default-market', // Add other fields you might need
      };
    } else {
      console.warn('User document does not exist');
      return {
        handle: '@username',
        profilePic: profilePlaceholder,
        market: 'default-market',
      };
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    return {
      handle: '@username',
      profilePic: profilePlaceholder,
      market: 'default-market',
    };
  }
};

export default fetchUserData;