import { useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { getAuth, fetchSignInMethodsForEmail } from "firebase/auth";
import { db } from "../Firebase";
import PrimaryButton from "../components/PrimaryButton";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";

import submergeLogo from "../assets/submerge-logo.png";
import styles from "../styles/SignUp.css";

function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [errorEmail, setErrorEmail] = useState(false);
  const [duplicateEmailError, setDuplicateEmailError] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setErrorEmail(value !== "" && !emailRegex.test(value));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  // Function to check for duplicate email addresses in Firebase Auth
  const checkDuplicateEmail = async (email) => {
    try {
      const auth = getAuth();
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      return signInMethods.length > 0; // If there are sign-in methods, the email already exists
    } catch (error) {
      console.error("Error checking duplicate email:", error);
      return false;
    }
  };

  // Function to check if email is in the `beta` array of `authorizedUsers`
  const checkEmailAuthorization = async (email) => {
    try {
      const authorizedUsersRef = doc(db, "auth", "authorizedUsers");
      const docSnapshot = await getDoc(authorizedUsersRef);

      if (docSnapshot.exists()) {
        const authorizedUsers = docSnapshot.data();
        if (Array.isArray(authorizedUsers.beta)) {
          return authorizedUsers.beta.includes(email);
        }
      }
      return false;
    } catch (error) {
      console.error("Error checking email authorization:", error);
      return false;
    }
  };

  const handleEmailCheck = async (e) => {
    e.preventDefault();
    setAuthError(false);
    setDuplicateEmailError(false);

    if (!email.trim() || errorEmail) {
      setAuthError(true);
      return;
    }

    const isAuthorized = await checkEmailAuthorization(email);
    if (!isAuthorized) {
      setAuthError(true);
      return;
    }

    const isDuplicate = await checkDuplicateEmail(email);
    if (isDuplicate) {
      setDuplicateEmailError(true);
      return;
    }

    setShowSignUpForm(true); // Proceed to show the sign-up form if checks pass
  };

  const isButtonDisabled = () => {
    return (
      !email.trim() ||
      !password.trim() ||
      !confirmPassword.trim() ||
      !termsAccepted
    );
  };

  return (
    <div className="sign-up">
      <div className="sign-up-container">
        <div className="left-panel">
          <div className="logo">
            <img className="submerge-logo" src={submergeLogo} alt="Logo" />
            <p className="tagline">UNDERGROUND MUSIC NETWORK</p>
          </div>
        </div>

        <div className="right-panel">
          <div className="sign-up-card">
            <div className="card-header">
              <h1 className="card-title">Sign Up</h1>
            </div>

            {showSignUpForm ? (
              <form className="sign-up-form">
                <div className="input-fields-section">
                  <div className="form-email form-field">
                    <label className="label">EMAIL</label>
                    <input
                      className="form-input"
                      type="email"
                      value={email}
                      placeholder="you@example.com"
                      readOnly
                    />
                  </div>

                  <div className="form-password form-field">
                    <label className="label">PASSWORD</label>
                    <input
                      className="form-input"
                      type="password"
                      placeholder="Enter your password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </div>

                  <div className="form-confirm-password form-field">
                    <label className="label">CONFIRM PASSWORD</label>
                    <input
                      className="form-input"
                      type="password"
                      placeholder="Re-enter your password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                  </div>

                  <div className="terms-privacy">
                    <input
                      type="checkbox"
                      id="terms-privacy"
                      checked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                      className="terms-privacy-checkbox"
                    />
                    <label htmlFor="terms-privacy" className="terms-privacy-label">
                      I accept the
                      <span>
                        <Link
                          to="https://submerge.live/terms-conditions"
                          target="_blank"
                          className="link-to-termsprivacy"
                        >
                          Terms & Conditions
                        </Link>
                      </span>
                      , and
                      <span>
                        <Link
                          to="https://submerge.live/privacy-policy"
                          target="_blank"
                          className="link-to-termsprivacy"
                        >
                          Privacy Policy
                        </Link>
                      </span>
                    </label>
                  </div>
                </div>

                <div className="form-buttons">
                  <PrimaryButton
                    buttonLabel="Sign Up"
                    className="button"
                    disabled={isButtonDisabled()}
                  />
                </div>
              </form>
            ) : (
              <form className="email-check-form" onSubmit={handleEmailCheck}>
                <div className="input-fields-section">
                  <div className="form-email form-field">
                    <label className="label">EMAIL</label>
                    <input
                      className="form-input"
                      type="email"
                      value={email}
                      placeholder="you@example.com"
                      onChange={handleEmailChange}
                      required
                    />
                    {authError && (
                      <p className="error-text">
                        This email is not authorized for sign-up.
                      </p>
                    )}
                    {duplicateEmailError && (
                      <p className="error-text">
                        This email is already in use.
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-buttons">
                  <PrimaryButton buttonLabel="Check Email" />
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;