import { collection, addDoc, getDoc, doc, serverTimestamp } from 'firebase/firestore';
import { saveMentions } from './saveMentions';
import { db } from '../Firebase'; // Ensure the Firebase instance is imported correctly

/**
 * Add a comment to a post and handle mentions.
 * @param {string} postId - The ID of the post being commented on.
 * @param {string} commentContent - The content of the comment.
 * @param {string} userId - The ID of the user making the comment.
 */
export const addComment = async (postId, commentContent, userId) => {
  try {
    // Validate input
    if (!postId || !userId) {
      throw new Error('Post ID or User ID is missing');
    }
    if (!commentContent.trim()) {
      console.warn('Comment content is empty. Skipping addComment.');
      return;
    }

    console.log('Adding comment:', { postId, userId, commentContent });

    // Reference to the comments sub-collection
    const commentsRef = collection(db, 'posts', postId, 'comments');

    // Add comment to Firestore
    const commentDocRef = await addDoc(commentsRef, {
      userID: userId,
      postID: postId,
      content: commentContent.trim(),
      timestamp: serverTimestamp(),
    });

    console.log('Comment added successfully:', commentDocRef.id);

    // Notify the post owner
    const postDocRef = doc(db, 'posts', postId);
    const postDoc = await getDoc(postDocRef);

    if (postDoc.exists()) {
      const postOwnerId = postDoc.data().userID;

      // Avoid notifying the post owner if they're commenting on their own post
      if (postOwnerId !== userId) {
        const notificationsRef = collection(db, 'notifications');
        await addDoc(notificationsRef, {
          read: false,
          recipientId: postOwnerId, // The owner of the post
          sourceId: commentDocRef.id, // The comment ID
          type: 'comment', // Define the notification type
          senderId: userId, // The user making the comment
          timestamp: serverTimestamp(),
        });

        console.log(`Notification created for post owner (ID: ${postOwnerId}) about the comment.`);
      }
    } else {
      console.warn(`Post with ID ${postId} not found. Skipping owner notification.`);
    }

    // Save mentions in the comment if any
    await saveMentions(commentContent, userId, postId);

    return commentDocRef.id; // Return the ID of the created comment
  } catch (error) {
    console.error('Error adding comment:', error);
    throw error;
  }
};