import { useEffect, useState } from 'react';
import { collection, doc, updateDoc, onSnapshot, orderBy, query, where, getDoc } from 'firebase/firestore';
import { auth, db } from '../Firebase';
import styles from '../styles/NotificationPopup.css';
import userAvatar from '../assets/avatar.jpg'; // Placeholder avatar
import PrimaryButton from './PrimaryButton';

const NotificationPopup = () => {
  const [notifications, setNotifications] = useState([]);

  // Function to fetch user handle from Firestore
  const fetchUserHandle = async (userId) => {
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      return userDoc.exists() ? userDoc.data().handle : 'Unknown User';
    } catch (error) {
      console.error('Error fetching user handle:', error);
      return 'Unknown User';
    }
  };

  useEffect(() => {
    const fetchNotifications = () => {
      const user = auth.currentUser;

      if (!user) {
        console.error('User not authenticated.');
        return;
      }

      const notificationsRef = collection(db, 'notifications');
      const q = query(
        notificationsRef,
        where('recipientId', '==', user.uid), // Fetch notifications for the current user
        orderBy('timestamp', 'desc') // Order by most recent
      );

      const unsubscribe = onSnapshot(q, async (snapshot) => {
        // Group notifications by sourceId
        const notificationsMap = {};
        const fetchedNotifications = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const notifData = doc.data();
            const senderHandle = await fetchUserHandle(notifData.senderId);

            if (!notificationsMap[notifData.sourceId]) {
              notificationsMap[notifData.sourceId] = {
                ...notifData,
                id: doc.id,
                senderHandles: [senderHandle],
              };
            } else {
              notificationsMap[notifData.sourceId].senderHandles.push(senderHandle);
            }
            return null; // Required for map function; the actual notifications are built in notificationsMap
          })
        );

        // Convert grouped notifications into an array
        const groupedNotifications = Object.values(notificationsMap).map((notif) => {
          const { senderHandles } = notif;
          const formattedHandles =
            senderHandles.length > 1
              ? senderHandles.slice(0, -1).join(', ') + ' and ' + senderHandles.slice(-1)
              : senderHandles[0];

          return {
            ...notif,
            formattedHandles,
          };
        });

        setNotifications(groupedNotifications);
      });

      return unsubscribe;
    };

    const unsubscribe = fetchNotifications();
    return () => unsubscribe && unsubscribe();
  }, []);

  const markAsRead = async (notificationId) => {
    const notificationRef = doc(db, 'notifications', notificationId);
    await updateDoc(notificationRef, { read: true });
  };

  return (
    <div className="notification-popup component">
      <div className="container">
        {notifications.length > 0 ? (
          notifications.map((notif) => (
            <div
              key={notif.id}
              className={`notification-row ${notif.read ? '' : 'unread'}`}
              onClick={() => markAsRead(notif.id)}
            >
              <div className="left-aligned-content">
                <div className="notif-thumbnail">
                  {/*<img src={userAvatar} alt="Notification Thumbnail" />*/}
                </div>
                <div className="notif-content">
                  <p className="notif-body">
                    {notif.formattedHandles} {notif.type} your post
                  </p>
                </div>
              </div>

              <div className="right-aligned-content">
                <span>
                  {notif.timestamp?.toDate().toLocaleDateString('en-US')} {' | '}
                  {notif.timestamp?.toDate().toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  })}
                </span>
                {!notif.read && <div className="new-notif-indicator"></div>}
              </div>
            </div>
          ))
        ) : (
          <p className="no-notifications">No new notifications</p>
        )}
      </div>
    </div>
  );
};

export default NotificationPopup;