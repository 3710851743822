import { useEffect, useState } from "react";
import { auth, db } from '../Firebase';
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import styles from '../styles/FeaturedSection.css'
import * as Icon from 'react-feather';
import trackPlaceholder from '../assets/track-placeholder.svg'
import merchPlaceholder from '../assets/merch-placeholder.svg'
import eventPlaceholder from '../assets/event-placeholder.svg'
import TertiaryButton from '../components/TertiaryButton'
import UploadTrack from '../components/UploadTrack'
import CreateEvent from '../components/CreateEvent'
import CreateMerch from '../components/CreateMerch'
import TrackDetails from '../components/TrackDetails'
import MerchDetails from '../components/MerchDetails'
import EventDetailsModal from '../components/EventDetailsModal'

const FeaturedSection = ( {viewUserName, userId}) => {
  console.log("User", )
  const [isMediaActive, setIsMediaActive] = useState(true);
  const [isMerchActive, setIsMerchActive] = useState(false);
  const [isEventsActive, setIsEventsActive] = useState(false);
  const [events, setEvents] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  
  const authUser = auth.currentUser.uid;
  console.log("Auth User: ", authUser)

  console.log("viewUser", viewUserName)
  console.log("Received userId", userId)

  const toggleMediaTab = () => {
    setIsMediaActive(true)
    setIsMerchActive(false)
    setIsEventsActive(false)
  }

  const toggleMerchTab = () => {
    setIsMediaActive(false)
    setIsMerchActive(true)
    setIsEventsActive(false)
  }

  const toggleEventsTab = () => {
    setIsMediaActive(false)
    setIsMerchActive(false)
    setIsEventsActive(true)
  }

  const [isUploadTrackVisible, setIsUploadTrackVisible] = useState(false);
  const [isCreateEventVisible, setIsCreateEventVisible] = useState(false);
  const [isCreateMerchVisible, setIsCreateMerchVisible] = useState(false);
  const [isTrackDetailsVisible, setIsTrackDetailsVisible] = useState(false);
  const [isMerchDetailsVisible, setIsMerchDetailsVisible] = useState(false);
  const [isEventDetailsVisible, setIsEventDetailsVisible] = useState(false);

  const toggleUploadTrackPopup = () => {
    setIsUploadTrackVisible(!isUploadTrackVisible);
  }

  const toggleCreateMerchPopup = () => {
    setIsCreateMerchVisible(!isCreateMerchVisible);
  }

  const toggleCreateEventPopup = () => {
    setIsCreateEventVisible(!isCreateEventVisible);
  }

  const toggleTrackDetailsPopup = () => {
    setIsTrackDetailsVisible(!isTrackDetailsVisible);
  }

  const toggleMerchDetailsPopup = () => {
    setIsMerchDetailsVisible(!isMerchDetailsVisible);
  }

  const toggleEventDetails = (event = null) => {
    console.log("CLICK!")
    setSelectedEvent(event);
    setIsEventDetailsVisible(!!event); // Open the modal only if an event is passed
  };

  //fetch events
  useEffect(() => {
    const fetchEvents = async () => {
      if (!userId) return;

      try {
        const eventsRef = collection(db, 'events');
        const q = query(eventsRef, where('promoters', 'array-contains', userId));
        orderBy('date', 'asc')
        const querySnapshot = await getDocs(q);

        let fetchedEvents = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        fetchedEvents = fetchedEvents.sort((a, b) => a.date.toDate() - b.date.toDate());
        setEvents(fetchedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, [currentUser]);
  
  //fetch tracks
  useEffect(() => {
    const fetchTracks = async () => {
      if (!userId) return;
  
      try {
        const tracksRef = collection(db, "tracks");
        const q = query(tracksRef, where("userId", "==", userId), orderBy("uploadedAt", "desc"));
        const querySnapshot = await getDocs(q);

  
        const fetchedTracks = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("Fetched Tracks: ", fetchedTracks )
  
        setTracks(fetchedTracks);
      } catch (error) {
        console.error("Error fetching tracks:", error);
      }
    };
  
    fetchTracks();
  }, [userId]);

  return (
    <div className="featured-section-component">
    	<div className="featured-section-container">

    		<div className="featured-tabs">
    			<div className="featured-tab-item tab" onClick={toggleMediaTab}>
            <p style={{ color: isMediaActive ? 'var(--cyan)' : 'var(--text-grey)' }}>Media/Music</p>
            <div className="tab-underline" style={{ backgroundColor: isMediaActive ? 'var(--cyan)' : 'transparent' }}></div>
    			</div>
          <div className="featured-tab-item tab" onClick={toggleMerchTab}>
            <p style={{ color: isMerchActive ? 'var(--cyan)' : 'var(--text-grey)' }}>Merchandise</p>
            <div className="tab-underline" style={{ backgroundColor: isMerchActive ? 'var(--cyan)' : 'transparent' }}></div>
          </div>
          <div className="featured-tab-item tab" onClick={toggleEventsTab}>
            <p style={{ color: isEventsActive ? 'var(--cyan)' : 'var(--text-grey)' }}>Events</p>
            <div className="tab-underline" style={{ backgroundColor: isEventsActive ? 'var(--cyan)' : 'transparent' }}></div>
          </div>
    		</div>

        {/* MEDIA/MUSIC TAB */}
        { isMediaActive && (
          <div className="media-music">

            {/* ONLY SHOW ON OWN USER'S PROFILE */}
            {userId === authUser && (
              <div className="upload-track" onClick={toggleUploadTrackPopup}>
                <div className="icon">
                  <Icon.Music size={18} color = "#0094D4"/>
                </div>
                <p className="">Upload new track</p>
              </div>
            )}
            
            
            {/* LIST OF TRACKS */}
            <div className="track-list">
              {tracks.length > 0 ? (
                tracks.map((track) => (
                  <div key={track.id} className="track-item">
                    <div className="track-thumbnail" onClick={toggleTrackDetailsPopup}>
                      <img src={track.thumbnail || trackPlaceholder} alt="Track Thumbnail" />
                    </div>
                    <div className="track-details">
                      <p className="track-artists">
                        {track.artists && track.artists.length > 0
                          ? track.artists.join(", ")
                          : "Unknown Artist"}
                      </p>
                      <p className="track-title">{track.title || "Untitled Track"}</p>
                      <p className="track-album-year">{track.releasedYear || "Unknown Year"}</p>
                    </div>
                    <div className="play-button">
                      <Icon.Play size={18} />
                    </div>
                  </div>
                ))
              ) : (
                <p>No tracks uploaded yet.</p>
              )}
            </div>  
          </div>
        )}

        {/* MERCH TAB */}
        { isMerchActive && (
          <div className="merch">

            {/* ONLY SHOW ON OWN USER'S PROFILE */}
            {userId === authUser && (
              <div className="upload-merch" onClick={toggleCreateMerchPopup}>
                <div className="icon">
                  <Icon.PlusSquare size={18} color = "#0094D4"/>
                  <p className="">Create a listing</p>

                </div>
            </div>
            )}
            
            {/* LIST OF MERCH */}
            <div className="merch-item" onClick={toggleMerchDetailsPopup}>
              <div className="merch-thumbnail">
                <img src={merchPlaceholder} />
              </div>
              <div className="merch-details">
                <h3 className="merch-title">item name</h3>
                <p className="merch-description">His palms are sweaty, knees weak, arms are heavy
                  There's vomit on his sweater already, mom's spaghetti
                  He's nervous, but on the surface, he looks calm and ready
                  To drop bombs, but he keeps on forgetting
                  What he wrote down, the whole crowd goes so loud
                  He opens his mouth, but the words won't come out </p>
              </div>
            </div>
          </div>
        )}

        {/*****  EVENTS TAB *****/}
        {isEventsActive && (
          <div className="events">

            {/* ONLY SHOW ON OWN USER'S PROFILE */}
            {userId === authUser && (
              <div className="create-event" onClick={toggleCreateEventPopup} style={{ cursor: 'pointer' }}>
                <div className="icon-text">
                  <div className="icon">
                    <Icon.Calendar size={18} color="#0094D4" />
                  </div>
                  <p>Create Event</p>
                </div>
              </div>
            )}

            {/* LIST OF EVENTS */}
            {events.map((event, index) => (
              <div
                className="event-item"
                key={index}
                onClick={() => toggleEventDetails(event)}
              >
                <div className="event-thumbnail">
                  <img src={event.thumbnail || eventPlaceholder} alt={event.title || 'Event Thumbnail'} />
                </div>
                <div className="event-details">
                  <h3 className="event-title">{event.title}</h3>
                  <p className="event-date-loc">
                    <div className="event-date sub-details">
                      <span>
                        <Icon.Calendar size={11} style={{ margin: 'auto' }} />
                      </span>
                      <span className="date-text" style={{ fontSize: 'var(--text-md)', margin: 'auto 4px' }}>
                      {event.date
                        ? `${event.date.toDate().toLocaleDateString('en-US')} | ${event.date.toDate().toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true,
                          })}`
                        : 'Date'}
                      </span>
                    </div>
                    •

                    {event.location && (
                      <div className="event-location sub-details">
                        <span>
                          <Icon.MapPin size={11} style={{ margin: 'auto' }} />
                        </span>
                        {Array.isArray(event.location) ? (
                          event.location[1] ? (
                            <a
                              href={event.location[1]} // Google Maps link
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ fontSize: 'var(--text-md)', margin: 'auto 4px', color: 'var(--cyan)', textDecoration: 'underline' }}
                            >
                              {event.location[0]} {/* mainText */}
                            </a>
                          ) : (
                            <span
                              className="date-text"
                              style={{ fontSize: 'var(--text-md)', margin: 'auto 4px' }}
                            >
                              {event.location[0]} {/* mainText */}
                            </span>
                          )
                        ) : (
                          <span
                            className="date-text"
                            style={{ fontSize: 'var(--text-md)', margin: 'auto 4px' }}
                          >
                            {event.location} {/* Display string value directly */}
                          </span>
                        )}
                      </div>
                    )}
                    •

                    <div className="event-ticket sub-details">
                      <span>
                        <Icon.DollarSign size={11} style={{ margin: 'auto' }} />
                      </span>
                      <span className="date-text" style={{ fontSize: 'var(--text-md)', margin: 'auto 4px' }}>
                        {event.ticketPrice ? `${event.ticketPrice}` : 'Free Admission'}
                      </span>
                    </div>
                  </p>
                  <p className="event-description" style={{ whiteSpace: 'pre-wrap'}}>
                    {event.description}
                  </p>
                  <p className="event-date-posted">Posted {event.postedAgo || 'Recently'}</p>
                </div>
              </div>
            ))}
          </div>
        )}
    	</div>

      {/* OVERLAYS / POPUPS */}

      <div className="overlays">
          <UploadTrack  isVisible={isUploadTrackVisible} onClose={toggleUploadTrackPopup}/>
          <CreateEvent  isVisible={isCreateEventVisible} onClose={toggleCreateEventPopup}/>
          <CreateMerch  isVisible={isCreateMerchVisible} onClose={toggleCreateMerchPopup}/>

          <TrackDetails  isVisible={isTrackDetailsVisible} onClose={toggleTrackDetailsPopup}/>
          <MerchDetails  isVisible={isMerchDetailsVisible} onClose={toggleMerchDetailsPopup}/>
          <EventDetailsModal
            isVisible={isEventDetailsVisible}
            onClose={() => toggleEventDetails(null)}
            event={selectedEvent}
          />
      </div>
    </div>
  );
}

export default FeaturedSection;