import styles from '../styles/CreatePost.css';
import * as Icon from 'react-feather';
import BubblePill from './BubblePill';
import profilePlaceholder from '../assets/profile-placeholder.svg';
import { useEffect, useState } from "react";
import CreatePostPopup from '../components/CreatePostPopup';
import PrimaryButton from '../components/PrimaryButton';
import { saveMentions } from '../utils/saveMentions'
import { db, auth } from '../Firebase';
import { collection, addDoc, serverTimestamp, getDoc, doc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import fetchUserData from '../utils/fetchUserData';

const CreatePost = ({ userData, addPost }) => { // Destructure addPost from props
  const [isPostPopupVisible, setIsPostPopupVisible] = useState(false);
  const [content, setContent] = useState('');
  const [userID, setUserID] = useState(null);
  const [userHandle, setUserHandle] = useState('@username');
  const [profilePic, setProfilePic] = useState(profilePlaceholder); // Initialize with placeholder image
  const [userMarket, setUserMarket] = useState("global");

  const togglePopup = () => {
    setIsPostPopupVisible(!isPostPopupVisible);
  };

  useEffect(() => {
    const initializeUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserID(user.uid);
        try {
          const userData = await fetchUserData(user.uid, db);
          if (userData) {
            setUserHandle(`${userData.handle || 'username'}`);
            setProfilePic(userData.profilePic || profilePlaceholder);
            setUserMarket(userData.market || "global");
          }
        } catch (error) {
          console.error('Error initializing user data:', error);
        }
      } else {
        console.warn('No authenticated user found');
      }
    };

    initializeUserData();
  }, []);

  const handlePost = async () => {
    console.log('handlePost called');

    if (!content.trim()) {
      console.error('Please enter some content before posting.');
      return;
    }

    if (!userID) {
      console.error('User ID is not set');
      return;
    }

    try {
      let userData;
      if (!userHandle || userHandle === '@username') {
        userData = await fetchUserData(userID, db);
        setUserHandle(userData.handle);
        setProfilePic(userData.profilePic);
      } else {
        userData = { market: userMarket };
      }

      if (!userData || !userData.market) {
        console.error('User market is missing');
        return;
      }

      // Create the post in Firestore
      const postRef = await addDoc(collection(db, 'posts'), {
        userID: userID,
        content: content,
        mediaType: 'text',
        mediaURL: '',
        timestamp: serverTimestamp(),
        topics: ['discuss', userMarket],
        comments: '',
        likes: '',
      });

      console.log('Post created successfully:', postRef.id);
      setContent('');

      const newPost = {
        id: postRef.id,
        userID,
        content,
        mediaType: 'text',
        mediaURL: '',
        timestamp: new Date(),
        topics: ['discuss', userMarket],
        comments: '',
        likes: '',
      };

      addPost(newPost); // Update the posts state in the parent component

      await saveMentions(content, userID, postRef.id);
      console.log('Mentions saved successfully.');
    } catch (error) {
      console.error('Error creating post:', error);
    }
  };

  return (
    <div className="create-post component">
      <div className="container">
        <div className="image-text">
          <div className="user-image">
            <img src={profilePic} alt="User Profile" /> {/* Display fetched profilePic */}
          </div>
          <div className="content">
            <p className="user-handle">{userHandle}</p>
            <textarea
              className="post-content"
              placeholder="What's up?"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <div className="post-actions">
              <div className="add-content">
                <Icon.Image color='#0094D4' size={24} style={{ margin: 'auto 0' }} />
                <BubblePill bubbleText="+ Add Bubble" />
              </div>
              <div className="btn">
                <PrimaryButton
                  buttonLabel="POST"
                  onClick={() => {
                    console.log("I got clicked");
                    handlePost();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* POPUP */}
      <CreatePostPopup isVisible={isPostPopupVisible} onClose={togglePopup} />
    </div>
  );
};

export default CreatePost;