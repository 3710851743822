import styles from '../styles/SinglePostPopup.css';
import * as Icon from 'react-feather';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { auth, db, storage } from '../Firebase';
import { doc, getDoc, setDoc, addDoc, deleteDoc, collection, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage'; // Import getDownloadURL and ref
import { addComment } from '../utils/addComment';
import { fetchComments } from '../utils/fetchComments';
import { fetchLikes } from '../utils/fetchLikes';
import { fetchCommentCount } from '../utils/fetchCommentCount';
import { toggleLike } from '../utils/toggleLike';

import BubblePill from './BubblePill';
import TertiaryButton from './TertiaryButton';
import SecondaryButton from './SecondaryButton';
import profilePlaceholder from '../assets/profile-placeholder.svg';
import MessagePrompt from './MessagePrompt';
import SharePostModal from './SharePostModal';


const SinglePostPopup = ({ isVisible, onClose, post }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(post.likesCount || 0);
  const [commentCount, setCommentCount] = useState(0);
  const [commentContent, setCommentContent] = useState('');
  const [comments, setComments] = useState([]);
  const [isPostOptionsVisible, setIsPostOptionsVisible] = useState(false);
  const [isMessagePromptVisible, setIsMessagePromptVisible] = useState(false);
  const [isSharePostModalVisible, setIsSharePostModalVisible] = useState(false);
  const [promptTitle, setPromptTitle] = useState("Default Title");
  const [promptBody, setPromptBody] = useState("Default Body");

  const togglePostOptionsModal = () => setIsPostOptionsVisible(!isPostOptionsVisible);
  const toggleSharePostModal = () => setIsSharePostModalVisible(!isSharePostModalVisible);

  const openMessagePrompt = () => {
    setPromptTitle("Delete Post");
    setPromptBody("Are you sure you want to delete this message? Once deleted you won't be able to undo it.");
    setIsMessagePromptVisible(true);
  };

  const closeMessagePrompt = () => {
    setIsMessagePromptVisible(false);
  };

  /*
  // Toggle Like
  const toggleLike = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const likesRef = collection(db, 'posts', post.id, 'likes');
        const likeDocRef = doc(likesRef, user.uid);
        const likeDoc = await getDoc(likeDocRef);

        if (likeDoc.exists()) {
          await deleteDoc(likeDocRef);
          setLikeCount((prev) => prev - 1);
          setIsLiked(false);
        } else {
          await setDoc(likeDocRef, {
            userID: user.uid,
            postID: post.id,
            timestamp: serverTimestamp(),
          });
          setLikeCount((prev) => prev + 1);
          setIsLiked(true);
        }
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };
  */

  /* Add Comment
  const addComment = async () => {
    console.log("Post button was clicked");
    try {
      const user = auth.currentUser;
      if (user && commentContent.trim()) {
        console.log("Authenticated user:", user.uid);
        console.log("Content of the comment:", commentContent);

        const commentsRef = collection(db, 'posts', post.id, 'comments');
        const docRef = await addDoc(commentsRef, {
          userID: user.uid,
          postID: post.id,
          timestamp: serverTimestamp(),
          content: commentContent,
        });
        console.log("Comment added successfully with ID:", docRef.id);

        setCommentContent('');
      } else {
        console.warn("No authenticated user or empty comment content.");
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  }; */

  // Fetch Comments and Comment Count
  useEffect(() => {
    if (post.id) {
      const commentsRef = collection(db, 'posts', post.id, 'comments');
  
      // Subscribe to comments changes
      const unsubscribeComments = onSnapshot(commentsRef, async (snapshot) => {
        setCommentCount(snapshot.size); // Update comment count
        
        // Fetch and process comments
        const fetchedComments = await fetchComments(snapshot.docs, db, storage, profilePlaceholder);
        setComments(fetchedComments); // Update the state with processed comments
      });
  
      // Subscribe to likes changes using fetchLikes utility
      const unsubscribeLikes = fetchLikes(post.id, db, setLikeCount, setIsLiked, auth.currentUser?.uid);
  
      // Cleanup on unmount
      return () => {
        unsubscribeComments();
        unsubscribeLikes();
      };
    }
  }, [post.id]);

  if (!isVisible) return null;

  return (
    <div className="single-post-overlay">
      <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
        <div className="container-header">
          <Link 
              to={{
                pathname: `/${post.userHandle}`  // Use post.userID dynamically in the URL
              }}
              onClick={() => console.log("Navigating to userID: ", post.userID)}
              style={{ textDecorationLine: 'none' }}
          >
            <p>
              @{post.userHandle}{post.userHandle.endsWith('s') ? "'" : "'s"} post
            </p>
          </Link>
          <div onClick={onClose}>
            <Icon.X size={22} />
          </div>
        </div>

        <hr />

        <div className="single-post-content">
          <div className="posted-by">
            <div className="posted-by-image">
              <Link 
                to={{
                  pathname: `/${post.userHandle}`  // Use post.userID dynamically in the URL
                }}
                onClick={() => console.log("Navigating to userID: ", post.userID)}
                style={{ textDecorationLine: 'none' }}
              >
                <img src={post.userProfilePic || profilePlaceholder} alt="User Avatar" />
              </Link>
            </div>
            <div className="posted-by-details">
              <div className="posted-by-user">
                <Link 
                  to={{
                    pathname: `/${post.userHandle}`  // Use post.userID dynamically in the URL
                  }}
                  onClick={() => console.log("Navigating to userID: ", post.userID)}
                  style={{ textDecorationLine: 'none' }}
                >
                  <p className="posted-by-name">{post.userHandle}</p>
                </Link>
                <div className="follow-button">
                  <TertiaryButton buttonLabel="+ Follow" />
                </div>
              </div>
              <span className="posted-by-date">
                
                {post.timestamp?.toDate().toLocaleDateString('en-US')} {' | '}  
                {post.timestamp?.toDate().toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true
                })}
              </span>
            </div>
            <div className="post-more-icon" onClick={togglePostOptionsModal}>
                <Icon.MoreVertical size={20} />
              </div>
              {/* OPTIONS MODAL */}
              {isPostOptionsVisible && (
                <div className="post-options-modal">
                  <ul className="post-options-list">
                    {/* HIDE DELETE & EDIT POST IF NOT currentUser */}
                    <li>
                      <Icon.Edit3 size={14} className="icon"/>
                      <span className="option-name">Edit Post</span>
                    </li>
                    <li onClick={() => openMessagePrompt()}>
                      <Icon.Trash size={14} className="icon"/>
                      <span className="option-name">Delete Post</span>
                    </li>
                    <li>
                      <Icon.UserPlus size={14} />
                      <span className="option-name">Follow @{post.userHandle || 'Unknown User'}</span>
                      
                    </li>
                    <li>
                      <Icon.Slash size={14} />
                      <span className="option-name">Block @{post.userHandle || 'Unknown User'}</span>
                    </li>
                    <li>
                      <span>💩</span>
                      <span className="option-name">Poop this post</span>
                    </li>
                  </ul>
                </div>
              )}
          </div>

          <p className="post-body">{post.content || "Content not available"}</p>

          <div className="post-actions">
            <div className="post-data-count">
              <span>{likeCount} Likes</span>
              <span> • </span>
              <span>{commentCount} Comments</span>
            </div>
            <div className="like-comment">
              <button onClick={toggleLike} className="like-button">
                <Icon.ThumbsUp 
                  size={21} 
                  fill={isLiked ? '#1D599C' : 'none'} 
                  color={isLiked ? '#0094D4' : '#fff'}
                />
              </button>
              <button className="comment-button">
                <Icon.MessageSquare size={22} />
              </button>
              <button onClick={toggleSharePostModal} className="share-button">
                <Icon.Share2 size={22} aria-label="Share Post" />
              </button>
            </div>
          </div>
        </div>

        <hr />

        {/* COMMENT SECTION */}
        <div className="comment-section">
          {comments.map((comment) => (
            <div key={comment.id} className="single-comment">
              <div className="commented-by">
                <div className="commented-by-image">
                  <Link 
                    to={{
                      pathname: `/${comment.commentUserHandle}`  // Use post.userID dynamically in the URL
                    }}
                     onClick={() => console.log("Navigating to userID: ", post.userID)}
                    style={{ textDecorationLine: 'none' }}
                  >
                    <img src={comment.commentUserProfilePic || profilePlaceholder} alt="oops" />
                  </Link>
                </div>
                <div className="commented-by-details">
                  <div className="commented-by-user">
                    <Link 
                      to={{
                        pathname: `/${comment.commentUserHandle}`  // Use post.userID dynamically in the URL
                      }}
                      onClick={() => console.log("Navigating to userID: ", post.userID)}
                      style={{ textDecorationLine: 'none' }}
                    >
                      <p className="commented-by-name">@{comment.commentUserHandle || 'Unknown User'}</p>
                    </Link>
                    <span className="commented-by-date">
                      {comment.timestamp?.toDate ? comment.timestamp.toDate().toLocaleString() : 'N/A'}
                    </span>
                  </div>
                  <div className="comment-body">
                    <p>{comment.content}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <hr className="divider-bottom" />

        {/* CREATE COMMENT SECTION */}
        <div className="create-comment">
          <div className="comment-user-image">
            <img src={profilePlaceholder} alt="User Profile" />
          </div>
          <textarea
            className="post-content"
            placeholder="Write a comment..."
            value={commentContent}
            onChange={(e) => setCommentContent(e.target.value)}
          />
          <div className="post-comment-button">
          <SecondaryButton
                  buttonLabel="Submit"
                  onClick={() => {
                    console.log('Post ID:', post.id);
                    console.log('Comment Content:', commentContent);
                    console.log('User ID:', auth.currentUser?.uid);
                    addComment(post.id, commentContent, auth.currentUser?.uid)
                      .then(() => {
                        setCommentContent(''); // Clear the input field after success
                        fetchCommentCount(post.id); // Refresh comment count
                        console.log('Comment added successfully!');
                      })
                      .catch((err) => console.error('Error while adding comment:', err));
                  }}
                />
          </div>
        </div>
      </div>

      {isMessagePromptVisible && (
        <MessagePrompt 
          isVisible={isMessagePromptVisible} 
          onClose={closeMessagePrompt}
          cancelBtn
          promptTitle={promptTitle}
          promptBody={promptBody}
        />
      )}

      {isSharePostModalVisible && (
        <SharePostModal 
          onClose={toggleSharePostModal}
        />
      )}
    </div>
  );
};

export default SinglePostPopup;