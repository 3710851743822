import { collection, getDocs } from 'firebase/firestore';

/**
 * Fetches the comment count for a given post and updates the provided state handler.
 * @param {string} postId - The ID of the post.
 * @param {object} db - Firestore database instance.
 * @param {Function} setCommentCounts - State handler for updating comment counts.
 */
export const fetchCommentCount = async (postId, db, setCommentCounts) => {
  try {
    const commentsRef = collection(db, 'posts', postId, 'comments');
    const commentsSnapshot = await getDocs(commentsRef);

    setCommentCounts((prevCounts) => ({
      ...prevCounts,
      [postId]: commentsSnapshot.size,
    }));
  } catch (error) {
    console.error('Error fetching comment count:', error);
  }
};