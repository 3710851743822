import styles from '../styles/EventDetailsModal.css'
import eventPlaceholder from '../assets/event-placeholder.svg'
import performerIcon from '../assets/performer-icon.svg'
import * as Icon from 'react-feather';


const EventDetailsModal = ({isVisible, onClose, event }) => {
    if (!isVisible || !event) return null; // Render nothing if modal is not visible or no event is passed
    
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    }
    return (
        <div className="event-details-modal" onClick={handleOverlayClick}>
            <div className="event-details-content">
                <div className="event-details-header">
                    <h2 className="event-title">{event.title}</h2>
                    <div className="close-overlay-button" onClick={onClose} style={{cursor: 'pointer'}}>
                        <Icon.X color='#fff' size={18} />
                    </div>
                </div>
                {/* <p className="event-date-posted">Posted 1mo ago</p> */}
                <div className="event-thumbnail">
                    <img src={event.thumbnail || eventPlaceholder} alt={event.title} />
                </div>
                <div className="event-date-loc">
                    <div className="event-date sub-details">
                        <div className="event-date-loc-icon">
                            <Icon.Calendar size={14} style={{margin: '0 0 -2px'}}/>
                        </div>
                        <p>
                            {event.date?.toDate().toLocaleDateString('en-US')}
                        </p>                   
                    </div>

                    {/* Hide location if empty */}
                    {event.location && (
                        <div className="event-location sub-details">
                        <div className="event-date-loc-icon">
                            <Icon.MapPin size={14} style={{margin: '0 0 -2px'}}/>
                        </div>
                        <p className="location-text">
                            {event.location}
                        </p>
                    </div>
                    )}
                    

                    <div className="event-ticket sub-details">
                        <div className="event-date-loc-icon">
                            <Icon.DollarSign size={14} style={{margin: '0 0 -2px'}}/>
                        </div>
                        <p className="ticket-text">
                            {event.ticketPrice || 'Free Admission'}
                        </p>
                    </div>

                    <div className="event-performers sub-details">
                        <div className="performer-icon">
                            <img src={performerIcon} />
                        </div>
                        <p className="performers-text">
                          {Array.isArray(event.performers)
                            ? event.performers.join(', ')
                            : event.performers
                          }
                          
                        </p>
                    </div>
                </div>

                <p className="event-description" style={{ whiteSpace: 'pre-wrap'}}>
                    {event.description}
                </p>

                <div className="invite-response">
                    <div className="response-option">
                        <Icon.Check size={16} color="var(--sand-beige)" />
                        <p>Going</p>
                    </div>
                    <div className="response-option">
                        <Icon.HelpCircle size={16} color="var(--sand-beige)" />
                        <p>Maybe</p>
                    </div>
                        <div className="response-option">
                        <Icon.X size={16} color="var(--sand-beige)" />
                        <p>Not Going</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventDetailsModal;
