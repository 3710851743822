import React, { useEffect, useState } from 'react';
import { auth, db, storage } from '../Firebase'; // Firebase config
import { doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore'; // Firestore functions
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import userAvatar from '../assets/profile-placeholder.svg';
import styles from '../styles/EditProfileInformation.css';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import { openDefaultEditor } from '@pqina/pintura';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginImageEdit);

const EditProfile = () => {
  const [userData, setUserData] = useState(null);
  const [inputNameValue, setInputNameValue] = useState('');
  const [inputUsernameValue, setInputUsernameValue] = useState('');
  const [inputBioValue, setInputBioValue] = useState('');
  const [inputLocationValue, setInputLocationValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [editedFile, setEditedFile] = useState(null);
  const [usernameError, setUsernameError] = useState(false);

  const handleNameChange = (e) => setInputNameValue(e.target.value);
  const handleUsernameChange = (e) => {
    setInputUsernameValue(e.target.value);
    setUsernameError(false); // Reset error when the user starts typing
  };
  const handleBioChange = (e) => setInputBioValue(e.target.value);
  const handleLocationChange = (e) => setInputLocationValue(e.target.value);

  useEffect(() => {
    const fetchUserData = async (uid) => {
      try {
        const userDocRef = doc(db, 'users', uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.profilePic && userData.profilePic.startsWith('gs://')) {
            const storageRef = ref(storage, userData.profilePic);
            userData.profilePic = await getDownloadURL(storageRef);
          }
          setUserData(userData);
          setInputNameValue(userData.userName || '');
          setInputUsernameValue(userData.handle || '');
          setInputBioValue(userData.bio || '');
          setInputLocationValue(userData.location || '');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        fetchUserData(currentUser.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  const checkUsernameAvailability = async (username) => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('handle', '==', username));
      const querySnapshot = await getDocs(q);
  
      // If any document is returned, the username is already taken
      return querySnapshot.empty; // Returns true if no document is found, false otherwise
    } catch (error) {
      console.error('Error checking username availability:', error);
      return false; // Default to unavailable in case of error
    }
  };
  
  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      if (auth.currentUser) {
        const uid = auth.currentUser.uid;
  
        // Check for duplicate usernames
        const isUsernameAvailable = await checkUsernameAvailability(inputUsernameValue);
        if (!isUsernameAvailable) {
          setUsernameError(true);
          alert('The username is already taken. Please choose another one.');
          setLoading(false);
          return;
        }
  
        const userDocRef = doc(db, 'users', uid);
  
        if (editedFile) {
          const storageRef = ref(storage, `Public/${uid}/${editedFile.name}`);
          await uploadBytes(storageRef, editedFile);
          const profilePicUrl = await getDownloadURL(storageRef);
  
          await setDoc(
            userDocRef,
            {
              userName: inputNameValue,
              handle: inputUsernameValue,
              bio: inputBioValue,
              location: inputLocationValue,
              profilePic: profilePicUrl,
            },
            { merge: true }
          );
        } else {
          await setDoc(
            userDocRef,
            {
              userName: inputNameValue,
              handle: inputUsernameValue,
              bio: inputBioValue,
              location: inputLocationValue,
            },
            { merge: true }
          );
        }
  
        alert('Profile updated successfully!');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile. Please try again.');
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  return (
    <div className="edit-profile-information">
      <form className="edit-profile-form" onSubmit={handleSave}>
        <div className="profile-mobile-userphoto">
          <img src={userData?.profilePic || userAvatar} className="header-avatar" alt="User Avatar" />
          <button type="button" className="change-photo-button" onClick={() => setShowModal(true)}>
            Change Photo
          </button>
        </div>

        <div className="input-fields-section">
          <div className="form-name form-field">
            <p className="label">NAME</p>
            <input
              placeholder="Your name"
              value={inputNameValue}
              onChange={handleNameChange}
              disabled={loading}
            />
          </div>

          <div className="form-username form-field">
            <p className="label">USERNAME</p>
            <input
              placeholder="Your username"
              value={inputUsernameValue}
              onChange={handleUsernameChange}
              disabled={loading}
            />
            {usernameError && (
              <div className="input-error-message">
                <p className="error-text">This username is already taken. Please try another one.</p>
              </div>
            )}
          </div>

          <div className="form-bio form-field">
            <p className="label">BIO</p>
            <input
              placeholder="Tell us about yourself"
              value={inputBioValue}
              onChange={handleBioChange}
              disabled={loading}
            />
          </div>

          <div className="form-location form-field">
            <p className="label">LOCATION</p>
            <input
              placeholder="Enter location"
              value={inputLocationValue}
              onChange={handleLocationChange}
              disabled={loading}
            />
          </div>

          <div className="form-buttons">
            <div className="button">
              <SecondaryButton buttonLabel="Cancel" id="button" disabled={loading} />
            </div>
            <div className="button">
              <PrimaryButton buttonLabel="Save" id="button" disabled={loading} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;