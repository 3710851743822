import styles from '../styles/RadioPlayer.css';
import radioPlaceholder from '../assets/cd-transparent.svg';
import * as Icon from 'react-feather';
import { useContext, useEffect, useRef, useState } from "react";
import { RadioContext } from "../context/RadioContext"
import { collection, getDocs } from "firebase/firestore";
import { db } from '../Firebase'; // Importing db from your Firebase setup

const RadioPlayer = () => {
    const { isRadioPlaying, setIsRadioPlaying } = useContext(RadioContext);
    
    useEffect(() => {
        if (isRadioPlaying) {
            console.log("Radio started playing...");
        } else {
            console.log("Radio stopped.");
        }
        return () => {
            console.log("RadioPlayer unmounted.");
        };
    }, [isRadioPlaying]);
        
    const [trackInfo, setTrackInfo] = useState({
        title: "Track Title",
        artist: "Artist",
    });
    const [radioStations, setRadioStations] = useState([]); // State for Firestore data
    const [currentStationIndex, setCurrentStationIndex] = useState(0); // Current station index

    // Reference to the audio element
    const audioRef = useRef(null);

    // Toggle play/pause
    const toggleRadioPlay = () => {
        if (!audioRef.current) return;

        if (isRadioPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }

        setIsRadioPlaying((prev) => !prev);
    };

    // Go to the previous station
    const playPreviousStation = () => {
        setCurrentStationIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : radioStations.length - 1
        );
    };

    // Go to the next station
    const playNextStation = () => {
        setCurrentStationIndex((prevIndex) =>
            prevIndex < radioStations.length - 1 ? prevIndex + 1 : 0
        );
    };

    // Fetch Firestore data
    const fetchRadioStations = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "radioStations"));
            const stations = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setRadioStations(stations);
        } catch (error) {
            console.error("Error fetching radio stations:", error);
        }
    };

    // Fetch track info for the current station
    const fetchTrackInfo = async () => {
        try {
            // Ensure you use backticks for template literals
            const currentStation = radioStations[currentStationIndex];
            if (!currentStation || !currentStation.stationId) {
                console.warn("Current station or stationId is missing");
                return;
            }
    
            const response = await fetch(`https://public.radio.co/stations/${currentStation.stationId}/status`);
            
            // Check if the response is OK
            if (!response.ok) {
                console.error("Error fetching track info: HTTP status", response.status);
                return;
            }
    
            const data = await response.json();
            console.log("Track Data Retrieved:", data);
    
            // Check if the data structure is as expected
            if (!data.current_track) {
                console.warn("Track data validation failed: 'current_track' is missing in the response:", data);
                return;
            }
            
            if (!data.current_track.title) {
                console.warn("Track data validation failed: 'title' is missing in 'current_track':", data);
                return;
            }
                      
            setTrackInfo({
                title: data.current_track.title,
                artist: data.current_track.artist,
            });
        } catch (error) {
            console.error("Error fetching track info:", error);
        }
    };
    
    useEffect(() => {
        // Fetch Firestore data
        fetchRadioStations();
    }, []);

    useEffect(() => {
        if (radioStations.length > 0) {
            const currentStation = radioStations[currentStationIndex];
            if (audioRef.current) {
                audioRef.current.src = currentStation.streamUrl;
                if (isRadioPlaying) {
                    audioRef.current.play();
                }
            }
            fetchTrackInfo(); // Initial fetch for the current station
    
            // Set up polling to fetch track info every 30 seconds
            const intervalId = setInterval(() => {
                fetchTrackInfo();
            }, 30000);
    
            // Cleanup interval when the component unmounts or dependencies change
            return () => clearInterval(intervalId);
        }
    }, [currentStationIndex, radioStations, isRadioPlaying]);

    return (
        <div className="radio-player-component">
            <div className='radio-player-container'>
                <div className='radio-player-image'>
                    <img src={radioPlaceholder} alt="Radio Logo" />
                </div>
                <div className='radio-music-info'>
                    <div className='radio-channel'>
                        <div className='live-dot'></div>
                        <p className='radio-channel-name'>
                            {radioStations.length > 0 ? radioStations[currentStationIndex].stationName : "Loading..."}
                        </p>
                    </div>
                    <p className='radio-now-playing'>
                        {trackInfo.title}
                    </p>
                </div>
                <div className='radio-controls'>
                    <div className='skip-button' onClick={playPreviousStation}>
                        <Icon.SkipBack size={16} color='var(--cyan-50)' />
                    </div>
                    {isRadioPlaying ? (
                        <div className='stop-button' onClick={toggleRadioPlay}>
                            <Icon.StopCircle size={28} color='var(--cyan)'/>
                        </div>
                    ) : (
                        <div className='play-button' onClick={toggleRadioPlay}>
                            <Icon.PlayCircle size={28} color='var(--cyan)'/>
                        </div>
                    )}
                    <div className='skip-button' onClick={playNextStation}>
                        <Icon.SkipForward size={16} color='var(--cyan-50)' />
                    </div>
                </div>
                {/* Hidden audio element */}
                <audio ref={audioRef} />
            </div>
        </div>
    );
}

export default RadioPlayer;