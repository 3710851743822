import React, { useState } from 'react';
import styles from '../styles/UserCard.css';
import { Link } from 'react-router-dom';
import profilePlaceholder from '../assets/profile-placeholder.svg';
import SecondaryButton from './SecondaryButton';
import PrimaryButton from './PrimaryButton';
import { auth, db } from '../Firebase';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';

const UserCard = ({ user, onFollow }) => {
  const [isFollowed, setIsFollowed] = useState(false); // State to track if the user is followed

  if (!user) {
    return null; // Return nothing if user data is not available
  }

  const handleFollow = async () => {
    try {
      const currentUser = auth.currentUser;
      if (currentUser) {
        // Update the current user's following list
        const userDocRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userDocRef, {
          following: arrayUnion(user.uid),
        });

        // Notify parent to update
        setIsFollowed(true); // Update button state
        if (onFollow) {
          onFollow(); // Trigger the onFollow callback
        }
      } else {
        console.error('No authenticated user found');
      }
    } catch (error) {
      console.error('Error following user:', error);
    }
  };

  return (
    <div className="user-card component">
      <div className="user-card-container">
        <div className="avatar-name">
          <div className="user-image-card">
            <Link 
              to={{
                pathname: `/${user.handle}`,
              }} 
              style={{ textDecorationLine: 'none' }}
            >
              <img src={user.profilePic || profilePlaceholder} alt={`${user.userName || 'User'}'s profile`} />
            </Link>
          </div>
          <div className='user-handle-name'>
            <Link 
              to={{
                pathname: `/${user.handle}`,
              }} 
              style={{ textDecorationLine: 'none' }}
            >
              <p className="user-name-card">{user.userName || "User's Name"}</p>
            </Link>
            <Link 
              to={{
                pathname: `/${user.handle}`,
              }} 
              style={{ textDecorationLine: 'none' }}
            >
              <p className="user-handle-card">@{user.handle || 'userhandle'}</p>
            </Link>
          </div>
        </div>
        
        
        <div className="button">
          {isFollowed ? (
            <SecondaryButton buttonLabel="FOLLOWED" disabled />
          ) : (
            <PrimaryButton buttonLabel="FOLLOW" onClick={handleFollow} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserCard;