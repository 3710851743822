import React from 'react';

const parseMentions = (text) => {
  if (typeof text !== 'string') {
    console.error('Invalid input to parseMentions:', text);
    return ''; // Return an empty string if the input is not valid
  }

  const mentionRegex = /@([a-zA-Z0-9_]+)/g; // Regex to match mentions like @username
  return text.split(mentionRegex).map((part, index) => {
    if (index % 2 === 1) {
      // It's a username match
      return (
        <a
          key={index}
          href={`https://social.submerge.live/${part}`} // Link to user profile
          className="mention-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          @{part}
        </a>
      );
    }
    return part; // Return plain text for non-mention parts
  });
};

export default parseMentions;