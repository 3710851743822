import React, { useEffect, useState } from 'react';
import { auth, db } from '../Firebase';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import styles from '../styles/Admin.css';
import AdminSidemenu from '../components/AdminSidemenu';
import PrimaryButton from '../components/PrimaryButton';

function Admin() {
  const [emailInput, setEmailInput] = useState('');
  const [fileContent, setFileContent] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentUserUid, setCurrentUserUid] = useState(null);
  const [uidsInField, setUidsInField] = useState([]);
  const [betaUsers, setBetaUsers] = useState([]);

  useEffect(() => {
    const checkUserAuthorization = async (uid) => {
      try {
        const adminDocRef = doc(db, 'auth', 'admins');
        const adminDoc = await getDoc(adminDocRef);

        if (adminDoc.exists()) {
          const data = adminDoc.data();
          const globalUidsArray = data.Global || [];
          setUidsInField(globalUidsArray);

          if (globalUidsArray.includes(uid)) {
            setIsAuthenticated(true);
          }
        } else {
          console.warn('No data found in Firestore under auth/admin.');
        }
      } catch (error) {
        console.error('Error fetching admin data:', error);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUserUid(user.uid);
        checkUserAuthorization(user.uid);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchBetaUsers = async () => {
    try {
      const authorizedUsersRef = doc(db, 'auth', 'authorizedUsers');
      const docSnap = await getDoc(authorizedUsersRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const betaUsersArray = data.beta || [];
        setBetaUsers(betaUsersArray.sort((a, b) => a.localeCompare(b))); // Sort alphabetically
      }
    } catch (error) {
      console.error('Error fetching beta users:', error);
    }
  };

  useEffect(() => {
    fetchBetaUsers();
  }, []);

  const handleEmailChange = (e) => setEmailInput(e.target.value);

  const handleEmailSubmit = async () => {
    if (!emailInput) {
      alert('Please enter a valid email.');
      return;
    }

    try {
      const authorizedUsersRef = doc(db, 'auth', 'authorizedUsers');
      await updateDoc(authorizedUsersRef, {
        beta: arrayUnion(emailInput),
      });
      alert('Email added successfully to authorizedUsers.');
      setEmailInput('');
      await fetchBetaUsers(); // Refetch beta users list after adding email
    } catch (error) {
      console.error('Error updating authorizedUsers:', error);
      alert('Error updating authorizedUsers. Please try again.');
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'text/plain' || file.type === 'text/csv')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const lines = event.target.result.split('\n').map((line) => line.trim()).filter((line) => line);
        setFileContent(lines);
      };
      reader.readAsText(file);
    } else {
      alert('Please upload a .txt or .csv file.');
    }
  };

  const handleFileSubmit = async () => {
    if (fileContent.length === 0) {
      alert('No valid content to upload.');
      return;
    }

    try {
      const authorizedUsersRef = doc(db, 'auth', 'authorizedUsers');
      for (const email of fileContent) {
        await updateDoc(authorizedUsersRef, {
          beta: arrayUnion(email),
        });
      }
      alert('File content added successfully to authorizedUsers.');
      setFileContent([]);
      await fetchBetaUsers(); // Refetch beta users list after adding emails from file
    } catch (error) {
      console.error('Error updating authorizedUsers:', error);
      alert('Error updating authorizedUsers. Please try again.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return (
      <div>
        <div>You do not have access to this page.</div>
        <div>Authenticated UID: {currentUserUid}</div>
        <div>Admin UIDs in Database: {uidsInField.join(', ')}</div>
      </div>
    );
  }

  return (
    <div className="admin">
      <AdminSidemenu />

      <div className="admin-container">
        <h3 className="admin-title">Beta Authorizations</h3>
        <div className="auth-container">
          <div className="input-email-section">
            <h3>Input Email</h3>
            <div className="form">
              <input
                type="email"
                value={emailInput}
                onChange={handleEmailChange}
                placeholder="Enter email to authorize"
              />
              <div className="button">
                <PrimaryButton onClick={handleEmailSubmit} buttonLabel="Add Email" />
              </div>
            </div>
          </div>

          <div className="file-upload-section">
            <h3>Upload .txt or .csv File</h3>
            <div className="form">
              <input type="file" accept=".txt, .csv" onChange={handleFileChange} />
              <div className="button">
                <PrimaryButton onClick={handleFileSubmit} buttonLabel="Upload File" />
              </div>
            </div>
          </div>
        </div>

        <h4 className="admin-title">All Beta Users List</h4>
        <ul className="beta-users-list">
          {betaUsers.map((user, index) => (
            <li key={index}>{user}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Admin;