import { useEffect, useState } from "react";
import styles from '../styles/NewsfeedCard.css';
import BubblePill from './BubblePill';
import ShimmerPost from './ShimmerPost';
import SinglePostPopup from './SinglePostPopup';
import MessagePrompt from './MessagePrompt';
import SharePostModal from './SharePostModal';
import profilePlaceholder from '../assets/profile-placeholder.svg';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom'; 
import { auth, db, storage } from '../Firebase';
import { collection, doc, query, where, addDoc, getDocs, getDoc, updateDoc, serverTimestamp, deleteDoc, setDoc, onSnapshot, orderBy } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import SecondaryButton from "./SecondaryButton";
import TertiaryButton from "./TertiaryButton";
import { saveMentions } from '../utils/saveMentions';
import parseMentions from "../utils/parseMentions";
import { toggleLike } from "../utils/toggleLike"
import { addComment } from "../utils/addComment";
import { toggleFollow } from "../utils/toggleFollow";

function NewsfeedCard({ isTrendingActive, isFollowingActive, trendingTopic }) {
  const [commentContent, setCommentContent] = useState(''); 
  const [commentCounts, setCommentCounts] = useState({});
  const [editingPostId, setEditingPostId] = useState(null); 
  const [editedContent, setEditedContent] = useState(''); 
  const [followingList, setFollowingList] = useState([]); 
  const [isFollowing, setIsFollowing] = useState({}); 
  const [isLiked, setIsLiked] = useState({});
  const [isMessagePromptVisible, setIsMessagePromptVisible] = useState(false);
  const [isPostOptionsVisible, setIsPostOptionsVisible] = useState(false);
  const [isSharePostModalVisible, setIsSharePostModalVisible] = useState(false);
  const [isSinglePostPopupVisible, setIsSinglePostPopupVisible] = useState(false);
  const [likeCounts, setLikeCounts] = useState({});
  const [originalContent, setOriginalContent] = useState(''); 
  const [popupVisibleForPost, setPopupVisibleForPost] = useState(null);
  const [posts, setPosts] = useState([]);
  const [promptBody, setPromptBody] = useState("Default Body");
  const [promptTitle, setPromptTitle] = useState("Default Title");
  const [selectedPost, setSelectedPost] = useState(null);
  const [visiblePostOptionsId, setVisiblePostOptionsId] = useState(null);
  
  const togglePostOptionsModal = (postId) => {
    setVisiblePostOptionsId((prev) => (prev === postId ? null : postId));
  };

  const toggleSharePostModal = () => setIsSharePostModalVisible(!isSharePostModalVisible);
 
  const openSinglePostPopup = (post) => {
    console.log("openSinglePostPopup called with post:", post);
  
    if (!post) {
      console.error("No post data provided to openSinglePostPopup.");
      return;
    }
    
    if (!post || typeof post.content !== "string") {
      console.error("Invalid post object or missing 'content':", post);
      return; // Prevent opening the popup for invalid posts
    }
  
    setSelectedPost(post);
    console.log("Selected post set to:", post);
  
    setIsSinglePostPopupVisible(true);
    console.log("Popup visibility set to true.");
  };

  const closeSinglePostPopup = () => {
    setIsSinglePostPopupVisible(false);
    setSelectedPost(null);
  };

  const openMessagePrompt = () => {
    setPromptTitle("Delete Post");
    setPromptBody("Are you sure you want to delete this message? Once deleted you won't be able to undo it.");
    setIsMessagePromptVisible(true);
  };

  const closeMessagePrompt = () => {
    setIsMessagePromptVisible(false);
  };

  const fetchCommentCount = async (postId) => {
    try {
      const commentsRef = collection(db, 'posts', postId, 'comments');
      const commentsSnapshot = await getDocs(commentsRef);
      setCommentCounts((prevCounts) => ({
        ...prevCounts,
        [postId]: commentsSnapshot.size
      }));
    } catch (error) {
      console.error('Error fetching comment count:', error);
    }
  };

  const fetchLikes = (postId) => {
    const likesRef = collection(db, 'posts', postId, 'likes');
    return onSnapshot(likesRef, (snapshot) => {
      setLikeCounts((prevCounts) => ({
        ...prevCounts,
        [postId]: snapshot.size, // Update like count directly from Firestore
      }));
      setIsLiked((prevLikes) => ({
        ...prevLikes,
        [postId]: snapshot.docs.some((doc) => doc.data().userID === auth.currentUser?.uid),
      }));
    });
  };

  /*
  const toggleLike = async (postId) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const likesRef = collection(db, 'posts', postId, 'likes');
        const likeDocRef = doc(likesRef, user.uid);
        const likeDoc = await getDoc(likeDocRef);
  
        if (likeDoc.exists()) {
          // If the user has already liked the post, remove their like
          await deleteDoc(likeDocRef);
        } else {
          // If the user hasn't liked the post, add their like
          await setDoc(likeDocRef, {
            userID: user.uid,
            postID: postId,
            timestamp: serverTimestamp(),
          });
        }
  
        // Fetch the updated like count
        fetchLikes(postId);
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  }; */

  /*
  const toggleFollow = async (userID) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const isCurrentlyFollowing = followingList.includes(userID);

        if (isCurrentlyFollowing) {
          // Unfollow
          await updateDoc(userDocRef, {
            following: followingList.filter((id) => id !== userID),
          });
          setFollowingList((prev) => prev.filter((id) => id !== userID));
        } else {
          // Follow
          await updateDoc(userDocRef, {
            following: [...followingList, userID],
          });
          setFollowingList((prev) => [...prev, userID]);
        }

        setIsFollowing((prev) => ({
          ...prev,
          [userID]: !isCurrentlyFollowing,
        }));
      }
    } catch (error) {
      console.error('Error toggling follow status:', error);
    }
  };
  */

  const fetchFollowingList = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const following = userDoc.data().following || [];
          setFollowingList(following);
        }
      }
    } catch (error) {
      console.error('Error fetching following list:', error);
    }
  };

  // Synchronize isFollowing whenever followingList or posts change
  useEffect(() => {
    const updateIsFollowing = () => {
      const newIsFollowing = {};
      posts.forEach((post) => {
        newIsFollowing[post.userID] = followingList.includes(post.userID);
      });
      setIsFollowing(newIsFollowing);
    };

    updateIsFollowing();
  }, [followingList, posts]);

  const fetchPostsWithUserHandles = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;
  
      let querySnapshot;
      if (isFollowingActive) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        const following = userDoc.exists() ? userDoc.data().following : [];
  
        if (following.length === 0) {
          setPosts([]);
          return;
        }
  
        const postsRef = collection(db, 'posts');
        const q = query(postsRef, where('userID', 'in', following));
        querySnapshot = await getDocs(q);
      } else if (isTrendingActive) {
        const postsRef = collection(db, 'posts');
  
        if (trendingTopic) {
          // Fetch posts matching the trending topic
          const q = query(postsRef, where('topics', 'array-contains', trendingTopic));
          querySnapshot = await getDocs(q);
        } else {
          // Fetch all posts if no trending topic is provided
          querySnapshot = await getDocs(postsRef);
        }
      }
  
      const postsWithCounts = await Promise.all(
        querySnapshot.docs.map(async (postDoc) => {
          const postData = postDoc.data();
          const userID = postData.userID;
  
          // Fetch user handle and profile picture
          const userDocRef = doc(db, 'users', userID);
          const userDoc = await getDoc(userDocRef);
          const userHandle = userDoc.exists() ? userDoc.data().handle : 'Unknown User';
          let userProfilePic = userDoc.exists() ? userDoc.data().profilePic : profilePlaceholder;
  
          if (userProfilePic && userProfilePic.startsWith('gs://')) {
            const profilePicRef = ref(storage, userProfilePic);
            userProfilePic = await getDownloadURL(profilePicRef);
          }
  
          // Fetch comment count
          const commentsRef = collection(db, 'posts', postDoc.id, 'comments');
          const commentsSnapshot = await getDocs(commentsRef);
          const commentCount = commentsSnapshot.size;
  
          // Fetch like count
          const likesRef = collection(db, 'posts', postDoc.id, 'likes');
          const likesSnapshot = await getDocs(likesRef);
          const likeCount = likesSnapshot.size;
  
          // Determine if the current user liked the post
          const isLikedByCurrentUser = likesSnapshot.docs.some(
            (doc) => doc.data().userID === user.uid
          );
  
          // Set initial states
          setCommentCounts((prev) => ({ ...prev, [postDoc.id]: commentCount }));
          setLikeCounts((prev) => ({ ...prev, [postDoc.id]: likeCount }));
          setIsLiked((prev) => ({ ...prev, [postDoc.id]: isLikedByCurrentUser }));
  
          return {
            id: postDoc.id,
            ...postData,
            userID,
            userHandle,
            userProfilePic,
          };
        })
      );
  
      // Sort posts by timestamp in descending order
      postsWithCounts.sort((a, b) => b.timestamp?.toDate() - a.timestamp?.toDate());
      setPosts(postsWithCounts);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const startEditingPost = (postId, currentContent) => {
    setEditingPostId(postId);
    setEditedContent(currentContent);
    setOriginalContent(currentContent); 
  };

  const cancelEditingPost = () => {
    setEditingPostId(null);
    setEditedContent('');
    setOriginalContent('');
  };

  const cancelEdit = () => {
    setEditingPostId(null); // Exit edit mode by clearing the editingPostId
    setEditedContent(''); // Reset the edited content state
  };

  const toggleEditMode = (postId) => {
    setVisiblePostOptionsId(null);
    setEditingPostId((prevId) => (prevId === postId ? null : postId));
    setEditedContent(posts.find((post) => post.id === postId)?.content || '');
  };

  
  const saveEditedPost = async (postId) => {
    try {
      // Check if `editedContent` is empty
      if (!editedContent.trim()) {
        console.error("Edited content is empty. Update aborted.");
        return;
      }
  
      const postDocRef = doc(db, 'posts', postId);
  
      // Retrieve the current content from Firestore
      const postSnapshot = await getDoc(postDocRef);
      const currentData = postSnapshot.data();
  
      if (!currentData) {
        console.error("Post not found in Firestore.");
        return;
      }
  
      const { content, editedContent: editedContentHistory = [] } = currentData;
  
      // Verify that `content` is a string
      if (typeof content !== "string") {
        console.error("The `content` field in Firestore is not a string.");
        return;
      }
  
      // Append the current content to the `editedContent` array
      const updatedEditedContent = [...editedContentHistory, content];
  
      // Update Firestore with the new content (as a string) and updated `editedContent` array
      await updateDoc(postDocRef, {
        content: String(editedContent.trim()), // Ensure this remains a trimmed string
        editedContent: updatedEditedContent, // Save previous versions
        editedTimestamp: serverTimestamp(), // Record the edit time
      });
  
      console.log("Post updated successfully with edited content and timestamp!");
  
      // Update local state to reflect changes
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId
            ? { ...post, content: String(editedContent.trim()), editedTimestamp: new Date() }
            : post
        )
      );
  
      // Reset editing states
      setEditingPostId(null);
      setEditedContent('');
    } catch (error) {
      console.error("Error updating post:", error);
    }
  };

  const deletePostWithConfirmation = async (postId) => {
    const userConfirmed = window.confirm("Are you sure you want to delete this post? This action cannot be undone.");
    if (!userConfirmed) return;
  
    try {
      const postDocRef = doc(db, 'posts', postId);
      const postSnapshot = await getDoc(postDocRef);
  
      if (!postSnapshot.exists()) {
        console.error("Post not found in Firestore.");
        return;
      }
  
      const postData = postSnapshot.data();
  
      // Copy the document to `deletedMessages` with the same document ID
      const deletedDocRef = doc(db, 'deletedPosts', postId);
      await setDoc(deletedDocRef, { ...postData, deletedTimestamp: serverTimestamp() });
  
      console.log("Post copied to deletedMessages successfully!");
  
      // Delete the document from `posts`
      await deleteDoc(postDocRef);
  
      console.log("Post deleted successfully from posts collection!");
  
      // Update local state to remove the post from the UI
      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  /*
  const addComment = async (postId) => {
  try {
    const user = auth.currentUser;

    if (!user) {
      console.error("User is not authenticated. Cannot add comment.");
      return;
    }

    if (!commentContent.trim()) {
      console.warn("Comment content is empty. Skipping addComment.");
      return;
    }

    console.log("Adding comment...", { userId: user.uid, postId, commentContent });

    const commentsRef = collection(db, 'posts', postId, 'comments');

    // Add comment to Firestore
    const newCommentRef = await addDoc(commentsRef, {
      userID: user.uid,
      postID: postId,
      timestamp: serverTimestamp(),
      content: commentContent.trim(),
    });

    console.log("Comment added successfully!", { commentId: newCommentRef.id });

    // Save mentions in the comment if they exist
    const mentionsResult = await saveMentions(commentContent, user.uid, postId);
    console.log("Mentions saved successfully:", mentionsResult);

    // Clear the comment content and close the popup
    setCommentContent('');
    setPopupVisibleForPost(null);

    // Update the comment count for the post
    await fetchCommentCount(postId);
    console.log("Comment count updated successfully for post:", postId);
  } catch (error) {
    console.error("Error adding comment:", error);
  }
};
*/

  useEffect(() => {
    fetchFollowingList();
    fetchPostsWithUserHandles();
  }, [isFollowingActive, isTrendingActive, trendingTopic, followingList]);

  return (
    <div className="newsfeed-card component">
      {posts.length > 0 ? posts.map((post) => (
        <div key={post.id} className="newsfeed-card-container">
          <div className="user-image">
            <Link 
              to={`/${post.userHandle}`} 
              style={{ textDecorationLine: 'none' }}
            >
              <img src={post.userProfilePic || profilePlaceholder} alt="User profile" />
            </Link>
          </div>
          <div className="post-content">
            <span className="time-ago">
              {post.timestamp?.toDate().toLocaleDateString('en-US')} {' | '}  
              {post.timestamp?.toDate().toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
              })}
            </span>

            {/****************************** POST OPTIONS ************************************/}
            <div className="post-more-icon" onClick={() => togglePostOptionsModal(post.id)}>
              <Icon.MoreVertical size={20} />
            </div>
            {visiblePostOptionsId === post.id && (
              <div className="post-options-modal">
                <ul className="post-options-list">
                  {/* Conditionally render Edit and Delete options */}
                  {post.userID === auth.currentUser?.uid && (
                    <>
                      <li onClick={() => toggleEditMode(post.id)}>
                        <Icon.Edit3 size={14} className="icon" />
                        <span className="option-name">Edit Post</span>
                      </li>
                      <li onClick={() => deletePostWithConfirmation(post.id)}>
                        <Icon.Trash size={14} className="icon" />
                        <span className="option-name">Delete Post</span>
                      </li>
                    </>
                  )}
                 {auth.currentUser?.uid !== post.userID && (
                  <li>
                    {isFollowing[post.userID] ? (
                      <button
                        onClick={() => {
                          console.log("Unfollowing user with ID:", post.userID);
                          toggleFollow(post.userID);
                        }}
                      >
                        <Icon.UserMinus size={14} />
                        <span className="option-name">Unfollow @{post.userHandle}</span>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          console.log("Following user with ID:", post.userID);
                          toggleFollow(post.userID);
                        }}
                      >
                        <Icon.UserPlus size={14} />
                        <span className="option-name">Follow @{post.userHandle}</span>
                      </button>
                    )}
                  </li>
                )}
                  <li>
                    <span>💩</span>
                    <span className="option-name">Poop this post</span>
                  </li>
                </ul>
              </div>
            )}

            <Link to={`/${post.userHandle}`} style={{ textDecorationLine: 'none' }}>
              <p className="user-handle">@{post.userHandle || 'Unknown User'}</p>
            </Link>
            
            <div className="content-area">
              {editingPostId === post.id ? (
                <div className="edit-post-area">
                  <textarea
                    value={editedContent}
                    onChange={(e) => setEditedContent(e.target.value)}
                    placeholder="Edit your post..."
                  />
                  <div className="edit-post-actions">
                    <button onClick={() => saveEditedPost(post.id)} className="save-button">
                      Save
                    </button>
                    <button onClick={() => cancelEdit()} className="cancel-button">
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <p className="content-text">{parseMentions(post.content)}</p>
              )}
            </div>

            {post.mediaURL && (
              <div className="photo-grid">
                <img src={post.mediaURL} alt="Post media" />
              </div>
            )}

            <div className="pill">
              {post.topics && post.topics.length > 0 && (
                post.topics
                  .filter((topic) => topic !== "Minnesota")
                  .map((filteredTopic, index) => (
                    <BubblePill key={index} bubbleText={filteredTopic} />
                  ))
              )}
            </div>

            {post.mediaURL && (
              <div className="photo-grid">
                <img src={post.mediaURL} alt="Post media" />
              </div>
            )}

            {/****************************** POST ACTIONS ************************************/}
            <div className="post-actions">
              <div className="post-data-count">
                <button className="comments-view" onClick={() => openSinglePostPopup(post)}>
                  <span>{commentCounts[post.id] || 0} Comments</span>
                </button>
                <span> • </span>
                <span>{likeCounts[post.id] || 0} Likes</span>
              </div>
              <div className="like-comment">
                <button onClick={() => toggleLike(post.id)} className="like-button">
                  <Icon.ThumbsUp 
                    size={21} 
                    fill={isLiked[post.id] ? '#1D599C' : 'none'} 
                    color={isLiked[post.id] ? '#0094D4' : '#fff'} 
                  />
                </button>
                <button onClick={() => setPopupVisibleForPost(post.id)} className="comment-button">
                  <Icon.MessageSquare size={22} aria-label="Comment On Post" />
                </button>
                <button onClick={toggleSharePostModal} className="share-button">
                  <Icon.Share2 size={22} aria-label="Share Post" />
                </button>
              </div>
            </div>

            {popupVisibleForPost === post.id && (
              <div className="comment-popup">
                <textarea
                  value={commentContent}
                  onChange={(e) => setCommentContent(e.target.value)}
                  placeholder="Say something..."
                />
                <SecondaryButton
                  buttonLabel="Submit"
                  onClick={() => {
                    console.log('Post ID:', post.id);
                    console.log('Comment Content:', commentContent);
                    console.log('User ID:', auth.currentUser?.uid);
                    addComment(post.id, commentContent, auth.currentUser?.uid)
                      .then(() => {
                        setCommentContent(''); // Clear the input field after success
                        fetchCommentCount(post.id); // Refresh comment count
                        console.log('Comment added successfully!');
                      })
                      .catch((err) => console.error('Error while adding comment:', err));
                  }}
                />
                <TertiaryButton 
                  buttonLabel="Cancel" 
                  onClick={() => {
                    console.log("Cancel button clicked");
                    setPopupVisibleForPost(null);
                  }} 
                />
              </div>
            )}
          </div>
        </div>
      )) : (
        <p className="no-result">No posts available</p>
      )}

      {isSinglePostPopupVisible && selectedPost && (
        <SinglePostPopup 
          isVisible={isSinglePostPopupVisible} 
          onClose={closeSinglePostPopup} 
          post={selectedPost}
        />
      )}
    </div>
  );
}

export default NewsfeedCard;