import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { RadioProvider } from './context/RadioContext'; // Import RadioProvider
import appbg from './assets/sbmrg-bg.png';

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <RadioProvider>
          <div className="app-container">
            {/* Background Image */}
            <div className="bg-img">
              <img src={appbg} className="bg-img-file" alt="Background" />
            </div>
            {/* Main App */}
            <App />
          </div>
        </RadioProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
} else {
  console.error("Root container not found in the DOM.");
}
