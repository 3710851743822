import styles from '../styles/ProfilePanel.css';
import * as Icon from 'react-feather';
import userAvatar from '../assets/avatar.jpg';
import TertiaryButton from './TertiaryButton';
import ShimmerProfile from './ShimmerProfile';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { auth, db } from '../Firebase';
import { doc, getDoc } from 'firebase/firestore';

function ProfilePanel() {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        console.log("Authenticated user ID:", user.uid);
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          console.log("User data from Firestore:", userDoc.data());
          setUserData(userDoc.data());
        } else {
          console.log('User document does not exist');
        }
      }
    };

    fetchUserData();
  }, []);

  if (!userData) {
    return <div><ShimmerProfile /></div>;
  }

  return (
    <div className="profile-panel">
      <div className="profile-panel-content">
        {console.log("navigating to profile UID: ", auth.currentUser.uid)}
        <Link 
            to={{
              pathname: `/${userData.handle}`, // Use handle for the URL
              //state: { userId: auth.currentUser?.uid} // Pass UID in the state for data fetching
            }} 
            style={{ textDecorationLine: 'none' }}
        >
          <img src={userData.profilePic || userAvatar} alt="User Avatar" />
        </Link>
        <div className="user-overview">
          <Link 
            to={{
              pathname: `/${userData.handle}`
            }} 
            style={{ textDecorationLine: 'none' }}
          >
            <h3>{userData.userName}</h3>
          </Link>
          <Link 
            to={{
              pathname: `/${userData.handle}`
            }} 
            style={{ textDecorationLine: 'none' }}
          >
            <p className="overview-user-handle">@{userData.handle}</p>
          </Link>
          <p className="overview-user-bio">{userData.bio || 'No bio available'}</p>

          <hr />
          <div className="overview-ff-count">
            <div className="overview-followers">
              <p className="ov-followers-count">{userData.followers ? userData.followers.length : 0}</p>
              <p className="ov-following-label">Followers</p>
            </div>
            <div className="overview-following">
              <p className="ov-following-count">{userData.following ? userData.following.length : 0}</p>
              <p className="ov-following-label">Following</p>
            </div>
          </div>
          <hr />
          <Link to="/edit_profile">
            <TertiaryButton buttonLabel="Edit Profile" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProfilePanel;