import { collection, onSnapshot } from 'firebase/firestore';

/**
 * Fetches likes for a given post and sets like count and liked state in the provided state handlers.
 * @param {string} postId - The ID of the post.
 * @param {object} db - Firestore database instance.
 * @param {Function} setLikeCounts - State handler for updating like counts.
 * @param {Function} setIsLiked - State handler for updating liked status.
 * @param {string} currentUserId - The ID of the current user.
 * @returns {Function} - Function to unsubscribe from the Firestore listener.
 */
export const fetchLikes = (postId, db, setLikeCounts, setIsLiked, currentUserId) => {
  const likesRef = collection(db, 'posts', postId, 'likes');

  return onSnapshot(likesRef, (snapshot) => {
    setLikeCounts((prevCounts) => ({
      ...prevCounts,
      [postId]: snapshot.size, // Update like count directly from Firestore
    }));

    setIsLiked((prevLikes) => ({
      ...prevLikes,
      [postId]: snapshot.docs.some((doc) => doc.data().userID === currentUserId),
    }));
  });
};