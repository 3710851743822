import { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { auth } from './Firebase'; // Import Firebase auth instance

import './App.css';

import Layout from "./components/Layout"; // Persistent layout with RadioPlayer
import Admin from './screens/Admin';
import SignIn from './screens/SignIn';
import SignUp from './screens/SignUp';
import ForgotPassword from './screens/ForgotPassword';
import MainScreen from './screens/MainScreen';
import Profile from './screens/Profile';
import AccountSettings from './screens/AccountSettings';
import Notifications from './screens/Notifications';
import Messages from './screens/Messages';
import Loading from './components/Loading';
import EventsPage from './screens/EventsPage';
import EventDetailsPage from './screens/EventDetailsPage';
import BetaUsers from './screens/BetaUsers'
import LandingPage from './screens/LandingPage';
import TermsAndConditions from './screens/TermsAndConditions';
import PrivacyPolicy from './screens/PrivacyPolicy';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });
    return () => unsubscribe(); // Clean up on unmount
  }, []);

  if (isAuthenticated === null) {
    return <div><Loading /></div>; // Loading state while authentication is being determined
  }

  return (
    <div className="App">
      <Routes>
        {/* Wrap all routes with the Layout */}
        <Route element={<Layout />}>
          {/* Authenticated Pages */}
          <Route
            path="/"
            element={isAuthenticated ? <MainScreen /> : <LandingPage />}
          />
          <Route path="/profile" element={isAuthenticated ? <MainScreen /> : <Navigate to="/signin" />} />
          <Route path="/profile/id" element={isAuthenticated ? <Profile /> : <Navigate to="/signin" />} />
          <Route path="/edit_profile" element={isAuthenticated ? <AccountSettings /> : <Navigate to="/signin" />} />
          <Route path="/notifications" element={isAuthenticated ? <Notifications /> : <Navigate to="/signin" />} />
          <Route path="/:handle" element={isAuthenticated ? <Profile /> : <Navigate to="/signin" />} />

          {/* Unauthenticated Pages */}
          <Route path="/admin" element={<Admin />} />
          <Route path="/welcome" element={<LandingPage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/event-details" element={<EventDetailsPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path ="/beta-users" element={<BetaUsers />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;