import { useEffect, useState } from "react";
import styles from '../styles/NewsfeedCard.css';
import BubblePill from './BubblePill';
import SinglePostPopup from './SinglePostPopup';
import MessagePrompt from './MessagePrompt';
import SharePostModal from './SharePostModal';
import profilePlaceholder from '../assets/profile-placeholder.svg';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import { auth, db, storage } from '../Firebase';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, orderBy, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import parseMentions from "../utils/parseMentions";
import SecondaryButton from "./SecondaryButton";
import TertiaryButton from "./TertiaryButton";
import { toggleLike } from "../utils/toggleLike";
import { addComment } from "../utils/addComment";
import { toggleFollow } from "../utils/toggleFollow";
import { saveEditedPost} from "../utils/saveEditedPost"
import { deletePostWithConfirmation } from "../utils/deletePostWithConfirmation";

function ProfileNewsFeedCard({ passedUID, trendingTopic }) {
  const [commentContent, setCommentContent] = useState('');
  const [commentCounts, setCommentCounts] = useState({});
  const [editingPostId, setEditingPostId] = useState(null); 
  const [editedContent, setEditedContent] = useState(''); 
  const [isLiked, setIsLiked] = useState({});
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [followingList, setFollowingList] = useState([]); 
  const [isFollowing, setIsFollowing] = useState({}); 
  const [isMessagePromptVisible, setIsMessagePromptVisible] = useState(false);
  const [isPostOptionsVisible, setIsPostOptionsVisible] = useState(false);
  const [isSharePostModalVisible, setIsSharePostModalVisible] = useState(false);
  const [isSinglePostPopupVisible, setIsSinglePostPopupVisible] = useState(false);
  const [likeCounts, setLikeCounts] = useState({});
  const [popupVisibleForPost, setPopupVisibleForPost] = useState(null);
  const [originalContent, setOriginalContent] = useState('')
  const [posts, setPosts] = useState([]);
  const [promptBody, setPromptBody] = useState("Default Body");
  const [promptTitle, setPromptTitle] = useState("Default Title");
  const [selectedPost, setSelectedPost] = useState(null);
  const [userData, setUserData] = useState(null);
  const [visiblePostOptionsId, setVisiblePostOptionsId] = useState(null);

  const togglePostOptionsModal = (postId) => {
    setVisiblePostOptionsId((prevId) => (prevId === postId ? null : postId));
    console.log("Toggling modal for post ID:", postId);
    console.log("Current visiblePostOptionsId:", visiblePostOptionsId);
  };

  const toggleSharePostModal = () => setIsSharePostModalVisible(!isSharePostModalVisible);

  const openSinglePostPopup = (post) => {
    setSelectedPost(post);
    setIsSinglePostPopupVisible(true);
  };

  const closeSinglePostPopup = () => {
    setIsSinglePostPopupVisible(false);
    setSelectedPost(null);
  };

  const openMessagePrompt = () => {
    setPromptTitle("Delete Post");
    setPromptBody("Are you sure you want to delete this message? Once deleted you won't be able to undo it.");
    setIsMessagePromptVisible(true);
  };

  const closeMessagePrompt = () => {
    setIsMessagePromptVisible(false);
  };

  /*
  const toggleLike = async (postId) => {
    try {
      const likesRef = collection(db, 'posts', postId, 'likes');
      const likeDocRef = doc(likesRef, passedUID);
      const likeDoc = await getDoc(likeDocRef);

      if (likeDoc.exists()) {
        await deleteDoc(likeDocRef);
        setLikeCounts((prev) => ({ ...prev, [postId]: (prev[postId] || 1) - 1 }));
      } else {
        await setDoc(likeDocRef, {
          userID: passedUID,
          postID: postId,
          timestamp: serverTimestamp(),
        });
        setLikeCounts((prev) => ({ ...prev, [postId]: (prev[postId] || 0) + 1 }));
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };
  */

  /*
  const addComment = async (postId) => {
    try {
      if (commentContent.trim()) {
        const commentsRef = collection(db, 'posts', postId, 'comments');
        await addDoc(commentsRef, {
          userID: passedUID,
          postID: postId,
          timestamp: serverTimestamp(),
          content: commentContent,
        });
        setCommentContent('');
        setPopupVisibleForPost(null);
        fetchCommentCount(postId);
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };
  */

  const fetchCommentCount = async (postId) => {
    try {
      const commentsRef = collection(db, 'posts', postId, 'comments');
      const commentsSnapshot = await getDocs(commentsRef);
      setCommentCounts((prevCounts) => ({
        ...prevCounts,
        [postId]: commentsSnapshot.size
      }));
    } catch (error) {
      console.error('Error fetching comment count:', error);
    }
  };

  const fetchLikes = (postId) => {
    const likesRef = collection(db, 'posts', postId, 'likes');
    return onSnapshot(likesRef, (snapshot) => {
      setLikeCounts((prevCounts) => ({
        ...prevCounts,
        [postId]: snapshot.size
      }));
      setIsLiked((prevLikes) => ({
        ...prevLikes,
        [postId]: snapshot.docs.some((doc) => doc.data().userID === passedUID)
      }));
    });
  };

  const fetchUserData = async () => {
    try {
      const userDocRef = doc(db, 'users', passedUID);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const fetchedUserData = userDoc.data();

        let userProfilePic = fetchedUserData.profilePic || profilePlaceholder;
        if (userProfilePic.startsWith('gs://')) {
          const profilePicRef = ref(storage, userProfilePic);
          userProfilePic = await getDownloadURL(profilePicRef);
        }

        setUserData({
          userHandle: fetchedUserData.handle || 'Unknown User',
          userName: fetchedUserData.userName,
          profilePic: userProfilePic,
        });
      } else {
        console.error('No user found for the provided UID');
        setUserData(null);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchUserPosts = async () => {
    try {
      if (!userData) return;
  
      const postsRef = collection(db, 'posts');
      const q = query(
        postsRef,
        where('userID', '==', passedUID),
        orderBy('timestamp', 'desc') // Sort by timestamp, newest first
      );
      const querySnapshot = await getDocs(q);
  
      const postsWithHandles = await Promise.all(
        querySnapshot.docs.map(async (postDoc) => {
          const postData = postDoc.data();
          fetchCommentCount(postDoc.id);
          const unsubscribeLikes = fetchLikes(postDoc.id);
  
          return {
            id: postDoc.id,
            unsubscribeLikes,
            ...postData,
            userProfilePic: userData.profilePic,
            userHandle: userData.userHandle,
          };
        })
      );
  
      setPosts(postsWithHandles);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [passedUID]);

  useEffect(() => {
    if (userData) {
      fetchUserPosts();
    }
  }, [userData]);

  useEffect(() => {
    return () => {
      posts.forEach((post) => {
        if (post.unsubscribeLikes) {
          post.unsubscribeLikes();
        }
      });
    };
  }, [posts]);

  /*
  const toggleFollow = async (userID) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const isCurrentlyFollowing = followingList.includes(userID);

        if (isCurrentlyFollowing) {
          // Unfollow
          await updateDoc(userDocRef, {
            following: followingList.filter((id) => id !== userID),
          });
          setFollowingList((prev) => prev.filter((id) => id !== userID));
        } else {
          // Follow
          await updateDoc(userDocRef, {
            following: [...followingList, userID],
          });
          setFollowingList((prev) => [...prev, userID]);
        }

        setIsFollowing((prev) => ({
          ...prev,
          [userID]: !isCurrentlyFollowing,
        }));
      }
    } catch (error) {
      console.error('Error toggling follow status:', error);
    }
  };
  */

  const startEditingPost = (postId, currentContent) => {
    setEditingPostId(postId);
    setEditedContent(currentContent);
    setOriginalContent(currentContent); 
  };

  const cancelEditingPost = () => {
    setEditingPostId(null);
    setEditedContent('');
    setOriginalContent('');
  };

  const cancelEdit = () => {
    setEditingPostId(null); // Exit edit mode by clearing the editingPostId
    setEditedContent(''); // Reset the edited content state
  };

  const toggleEditMode = (postId) => {
    setVisiblePostOptionsId(null);
    setEditingPostId((prevId) => (prevId === postId ? null : postId));
    setEditedContent(posts.find((post) => post.id === postId)?.content || '');
  };

  /*
  const saveEditedPost = async (postId) => {
    try {
      // Check if `editedContent` is empty
      if (!editedContent.trim()) {
        console.error("Edited content is empty. Update aborted.");
        return;
      }
  
      const postDocRef = doc(db, 'posts', postId);
  
      // Retrieve the current content from Firestore
      const postSnapshot = await getDoc(postDocRef);
      const currentData = postSnapshot.data();
  
      if (!currentData) {
        console.error("Post not found in Firestore.");
        return;
      }
  
      const { content, editedContent: editedContentHistory = [] } = currentData;
  
      // Verify that `content` is a string
      if (typeof content !== "string") {
        console.error("The `content` field in Firestore is not a string.");
        return;
      }
  
      // Append the current content to the `editedContent` array
      const updatedEditedContent = [...editedContentHistory, content];
  
      // Update Firestore with the new content (as a string) and updated `editedContent` array
      await updateDoc(postDocRef, {
        content: String(editedContent.trim()), // Ensure this remains a trimmed string
        editedContent: updatedEditedContent, // Save previous versions
        editedTimestamp: serverTimestamp(), // Record the edit time
      });
  
      console.log("Post updated successfully with edited content and timestamp!");
  
      // Update local state to reflect changes
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId
            ? { ...post, content: String(editedContent.trim()), editedTimestamp: new Date() }
            : post
        )
      );
  
      // Reset editing states
      setEditingPostId(null);
      setEditedContent('');
    } catch (error) {
      console.error("Error updating post:", error);
    }
  };
  */
  /*
  const deletePostWithConfirmation = async (postId) => {
    const userConfirmed = window.confirm("Are you sure you want to delete this post? This action cannot be undone.");
    if (!userConfirmed) return;
  
    try {
      const postDocRef = doc(db, 'posts', postId);
      const postSnapshot = await getDoc(postDocRef);
  
      if (!postSnapshot.exists()) {
        console.error("Post not found in Firestore.");
        return;
      }
  
      const postData = postSnapshot.data();
  
      // Copy the document to `deletedMessages` with the same document ID
      const deletedDocRef = doc(db, 'deletedPosts', postId);
      await setDoc(deletedDocRef, { ...postData, deletedTimestamp: serverTimestamp() });
  
      console.log("Post copied to deletedMessages successfully!");
  
      // Delete the document from `posts`
      await deleteDoc(postDocRef);
  
      console.log("Post deleted successfully from posts collection!");
  
      // Update local state to remove the post from the UI
      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };
*/

  useEffect(() => {
  if (trendingTopic) {
    setFilteredPosts(posts.filter((post) => post.topics?.includes(trendingTopic))); // Filter by trendingTopic
    console.log("Trending Topic:", trendingTopic); // Debugging log
  } else {
    setFilteredPosts(posts); // Show all posts if no trendingTopic is provided
  }
}, [posts, trendingTopic]);

  return (
    <div className="newsfeed-card component">
      {filteredPosts.length > 0 ? filteredPosts.map((post) => (
        <div key={post.id} className="newsfeed-card-container">
          <div className="user-image">
            <Link 
              to={`/${post.userHandle}`} 
              style={{ textDecorationLine: 'none' }}
            >
              <img src={post.userProfilePic || profilePlaceholder} alt="User profile" />
            </Link>
          </div>
          <div className="post-content">
            <span className="time-ago">
              {post.timestamp?.toDate().toLocaleDateString('en-US')} {' | '}  
              {post.timestamp?.toDate().toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
              })}
            </span>

            {/****************************** POST OPTIONS ************************************/}
            <div className="post-more-icon" onClick={() => {
              console.log("Toggling modal for post ID:", post.id);
              console.log("Current visiblePostOptionsId before toggle:", visiblePostOptionsId);
              togglePostOptionsModal(post.id)}}>
              <Icon.MoreVertical size={20} />
            </div>
            {visiblePostOptionsId === post.id && (
              <div className="post-options-modal">
                <ul className="post-options-list">
                  {/* Conditionally render Edit and Delete options */}
                  {post.userID === auth.currentUser?.uid && (
                    <>
                      <li onClick={() => toggleEditMode(post.id)}>
                        <Icon.Edit3 size={14} className="icon" />
                        <span className="option-name">Edit Post</span>
                      </li>
                      <li onClick={() => deletePostWithConfirmation(post.id)}>
                        <Icon.Trash size={14} className="icon" />
                        <span className="option-name">Delete Post</span>
                      </li>
                    </>
                  )}
                 {auth.currentUser?.uid !== post.userID && (
                  <li>
                    {isFollowing[post.userID] ? (
                      <button
                        onClick={() => {
                          console.log("Unfollowing user with ID:", post.userID);
                          toggleFollow(post.userID);
                        }}
                      >
                        <Icon.UserMinus size={14} />
                        <span className="option-name">Unfollow @{post.userHandle}</span>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          console.log("Following user with ID:", post.userID);
                          toggleFollow(post.userID);
                        }}
                      >
                        <Icon.UserPlus size={14} />
                        <span className="option-name">Follow @{post.userHandle}</span>
                      </button>
                    )}
                  </li>
                )}
                  <li>
                    <span>💩</span>
                    <span className="option-name">Poop this post</span>
                  </li>
                </ul>
              </div>
            )}

            <Link to={`/${post.userHandle}`} style={{ textDecorationLine: 'none' }}>
              <p className="user-handle">@{post.userHandle || 'Unknown User'}</p>
            </Link>
            
            <div className="content-area">
              {editingPostId === post.id ? (
                <div className="edit-post-area">
                  <textarea
                    value={editedContent}
                    onChange={(e) => setEditedContent(e.target.value)}
                    placeholder="Edit your post..."
                  />
                  <div className="edit-post-actions">
                    <button onClick={() => saveEditedPost(post.id)} className="save-button">
                      Save
                    </button>
                    <button onClick={() => cancelEdit()} className="cancel-button">
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <p className="content-text">{parseMentions(post.content)}</p>
              )}
            </div>

            {post.mediaURL && (
              <div className="photo-grid">
                <img src={post.mediaURL} alt="Post media" />
              </div>
            )}

            <div className="pill">
              {post.topics && post.topics.length > 0 && (
                post.topics
                  .filter((topic) => topic !== "Minnesota")
                  .map((filteredTopic, index) => (
                    <BubblePill key={index} bubbleText={filteredTopic} />
                  ))
              )}
            </div>

            {post.mediaURL && (
              <div className="photo-grid">
                <img src={post.mediaURL} alt="Post media" />
              </div>
            )}

            {/****************************** POST ACTIONS ************************************/}
            <div className="post-actions">
              <div className="post-data-count">
                <button className="comments-view" onClick={() => openSinglePostPopup(post)}>
                  <span>{commentCounts[post.id] || 0} Comments</span>
                </button>
                <span> • </span>
                <span>{likeCounts[post.id] || 0} Likes</span>
              </div>
              <div className="like-comment">
                <button onClick={() => toggleLike(post.id)} className="like-button">
                  <Icon.ThumbsUp 
                    size={21} 
                    fill={isLiked[post.id] ? '#1D599C' : 'none'} 
                    color={isLiked[post.id] ? '#0094D4' : '#fff'} 
                  />
                </button>
                <button onClick={() => setPopupVisibleForPost(post.id)} className="comment-button">
                  <Icon.MessageSquare size={22} aria-label="Comment On Post" />
                </button>
                <button onClick={toggleSharePostModal} className="share-button">
                  <Icon.Share2 size={22} aria-label="Share Post" />
                </button>
              </div>
            </div>

            {popupVisibleForPost === post.id && (
              <div className="comment-popup">
                <textarea
                  value={commentContent}
                  onChange={(e) => setCommentContent(e.target.value)}
                  placeholder="Say something..."
                />
                <SecondaryButton
                  buttonLabel="Submit"
                  onClick={() => {
                    console.log('Post ID:', post.id);
                    console.log('Comment Content:', commentContent);
                    console.log('User ID:', auth.currentUser?.uid);
                    addComment(post.id, commentContent, auth.currentUser?.uid)
                      .then(() => {
                        setCommentContent(''); // Clear the input field after success
                        fetchCommentCount(post.id); // Refresh comment count
                        console.log('Comment added successfully!');
                      })
                      .catch((err) => console.error('Error while adding comment:', err));
                  }}
                />
                <TertiaryButton 
                  buttonLabel="Cancel" 
                  onClick={() => {
                    console.log("Cancel button clicked");
                    setPopupVisibleForPost(null);
                  }} 
                />
              </div>
            )}
          </div>
        </div>
      )) : (
        <p className="no-result">No posts available</p>
      )}

      {isSinglePostPopupVisible && selectedPost && (
        <SinglePostPopup 
          isVisible={isSinglePostPopupVisible} 
          onClose={closeSinglePostPopup} 
          post={selectedPost}
        />
      )}
    </div>
  );
}

export default ProfileNewsFeedCard;