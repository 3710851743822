import styles from '../styles/FeaturedEvents.css'
import Slider from "react-slick";
import sampleImg from '../assets/avatar.jpg'
import React, { useState } from "react";

const FeaturedEvents = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const settings = {
        infinite: true,
        centerPadding: 0,
        centerMode: true,
        slidesToShow: 3,
        speed: 500,
        autoplay: true, // Enables autoplay
        autoplaySpeed: 3000, // Time between transitions (in milliseconds)
        dots: true, // Enable dots
        arrows: false, // Disable arrows
    };
    const items = [
        "https://i.pinimg.com/736x/62/cf/f6/62cff64dd4ad3de7a66acb60f4ec27b3.jpg",
        "https://i.pinimg.com/736x/25/a4/60/25a460a4b16777e5f6e5fb131216f7a1.jpg",
        "https://i.pinimg.com/736x/df/cb/19/dfcb19cce96f19b57dcc0ae83ba33c85.jpg",
        "https://i.pinimg.com/736x/37/09/bd/3709bd1a742416be9b83b2df73a60de0.jpg"
    ];
    return (
        <div className="featured-events-component">
            <div className="slider-container">
                <Slider {...settings}>
                    {items.map((src, index) => (
                        <div key={index} className='carousel-container'>
                        <div className='poster'>
                            <img src={src} />
                        </div>
                    </div>
                    ) )}
                </Slider>
            </div>
        </div>
    );
}

export default FeaturedEvents;
