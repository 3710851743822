import styles from '../styles/Loading.css'

const Loading = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-graphic">
        <div class="loading-spinner-ripple">
            <div class="ldio-x2uulkbinc">
                <div></div>
                <div></div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
