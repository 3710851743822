import styles from '../styles/CreateMessageModal.css';
import { useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import profilePlaceholder from '../assets/profile-placeholder.svg';
import PrimaryButton from '../components/PrimaryButton';
import { db, auth } from '../Firebase';
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';

const CreateMessageModal = ({ isVisible, onClose, onChatCreated, children }) => {
  const [predefinedOptions, setPredefinedOptions] = useState([]);
  const [inputRecipientValue, setInputRecipientValue] = useState('');
  const [values, setValues] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [messageContent, setMessageContent] = useState(''); // For the textarea
  const [error, setError] = useState(null); // Error message state
  const currentUser = auth.currentUser;

  // Fetch users from Firestore and populate predefinedOptions
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersRef = collection(db, 'users');
        const usersSnapshot = await getDocs(usersRef);

        const users = usersSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            display: `${data.username} (@${data.handle})`,
            uid: doc.id,
          };
        });

        setPredefinedOptions(users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleInputChange = (e) => {
    setInputRecipientValue(e.target.value);
    if (e.target.value.trim() !== '') {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const addValue = (value) => {
    if (!values.some((v) => v.uid === value.uid)) {
      setValues((prevValues) => [...prevValues, value]);
    }
    setInputRecipientValue('');
    setShowDropdown(false);
  };

  const handleOptionClick = (option) => {
    addValue(option);
  };

  const handleRemoveValue = (valueToRemove) => {
    setValues((prevValues) => prevValues.filter((value) => value.uid !== valueToRemove.uid));
  };

  const sendMessage = async () => {
    try {
      setError(null); // Clear any previous error

      // Step 1: Check if chat already exists
      const participants = [...values.map((v) => v.uid), currentUser.uid];
      const chatsRef = collection(db, 'chats');
      const existingChatQuery = query(
        chatsRef,
        where('participants', 'array-contains', currentUser.uid)
      );
      const existingChatSnapshot = await getDocs(existingChatQuery);

      const existingChat = existingChatSnapshot.docs.find((doc) => {
        const data = doc.data();
        return (
          data.participants.length === participants.length &&
          participants.every((p) => data.participants.includes(p))
        );
      });

      if (existingChat) {
        setError('A chat with the selected recipients already exists.');
        return;
      }

      // Step 2: Create a new chat
      const isGroupChat = participants.length > 2;
      const newChatRef = await addDoc(chatsRef, {
        isGroupChat,
        participants,
        lastUpdated: serverTimestamp(),
      });

      // Step 3: Create a new message in the messages collection
      const messagesRef = collection(db, 'messages');
      const newMessageRef = await addDoc(messagesRef, {
        chatId: newChatRef.id,
        content: messageContent,
        isReadBy: [],
        messageType: 'text',
        senderId: currentUser.uid,
        timestamp: serverTimestamp(),
      });

      // Step 4: Update the lastMessageId field in the chat document
      await updateDoc(doc(db, 'chats', newChatRef.id), {
        lastMessageId: newMessageRef.id,
      });

      // Step 5: Trigger the onChatCreated callback
      if (onChatCreated) {
        onChatCreated();
      }

      // Close modal after successful creation
      onClose();
    } catch (error) {
      console.error('Error sending message:', error);
      setError('An error occurred while sending the message.');
    }
  };

  if (!isVisible) return null;

  return (
    <div className="create-message-modal">
      {children}
      <div className="inner-modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <p>Create Message</p>
          <div className="close-icon" onClick={onClose}>
            <Icon.X size={16} />
          </div>
        </div>

        <hr />

        <div className="recipient-field">
          <div className="selected-options">
            {values.map((value, index) => (
              <div key={index} className="recipient-tag">
                {value.display}
                <button onClick={() => handleRemoveValue(value)}>
                  <Icon.X size={16} />
                </button>
              </div>
            ))}
          </div>

          <input
            type="text"
            value={inputRecipientValue}
            onChange={handleInputChange}
            placeholder="Type to add recipient"
            className="multi-input-recipient input-field"
          />

          {showDropdown && (
            <div className="recipient-options">
              <ul>
                {predefinedOptions
                  .filter(
                    (option) =>
                      option.display.toLowerCase().includes(inputRecipientValue.toLowerCase()) &&
                      !values.some((v) => v.uid === option.uid)
                  )
                  .map((option, index) => (
                    <li key={index} onClick={() => handleOptionClick(option)}>
                      {option.display}
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>

        <div className="textarea">
          <textarea
            className="compose-message"
            placeholder="Aa"
            value={messageContent}
            onChange={(e) => setMessageContent(e.target.value)}
          />
        </div>

        {error && <p className="error-message">{error}</p>}

        <div className="send-button">
          <PrimaryButton buttonLabel="Send" onClick={sendMessage} />
        </div>
      </div>
    </div>
  );
};

export default CreateMessageModal;